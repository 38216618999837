import Vue from 'vue';
import { DEFAULT_TEMPLATE_ID } from '@/const';
import Profile from '../services/profile.service';
import Transactions from '../services/transactions.service';
import Notifications from '../services/notifications.service';
import app from '../main';

const emptyTransaction = {
  receiver_iban: '',
  receiver_name: '',
  receiver_address: '',
  receiver_street: '',
  receiver_city: '',
  receiver_zip_code: '',
  receiver_country_code: '',
  receiver_sort_code: '',
  receiver_account: '',
  receiver_is_self: true,
  receiver_is_company: false,
  sum_currency: null,
  coin: 'BTC',
  description: '',
  reference: null,
  contact_email: '',
  payer_name: '',
  account: null,
  account_id: null,
  refund_address: null,
  currency: 'EUR',
};

export default {
  namespaced: true,
  state: {
    transaction: null,
    formTransaction: { ...emptyTransaction },
    history: null,
    vouchers: null,
    vouchersFilter: 'usable',
    isVoucherModalOpen: false,
    paymentType: 'regular',
    selectedFormTemplate: DEFAULT_TEMPLATE_ID,
  },
  mutations: {
    paymentType(state, payload) {
      state.paymentType = payload;
    },
    isVoucherModalOpen(state, payload) {
      state.isVoucherModalOpen = payload;
    },
    transaction(state, payload) {
      state.transaction = payload;
    },
    formTransaction(state, payload) {
      state.formTransaction = payload;
    },
    vouchersFilter(state, payload) {
      state.vouchersFilter = payload;
    },
    clearTransactionForm(state) {
      state.formTransaction = { ...emptyTransaction };
      state.selectedFormTemplate = DEFAULT_TEMPLATE_ID;
    },
    transactionFromTemplate(state, template) {
      // TODO: enable selecting account in templates later
      state.formTransaction.account = null;
      state.formTransaction.account_id = null;

      // Keep voucher selected
      delete state.formTransaction.transaction_key;

      const formTemplate = {
        ...template,
        receiver_is_self: template.receiver_is_self === null
          ? state.formTransaction.receiver_is_self
          : template.receiver_is_self,
        receiver_is_company: template.receiver_is_company === null
          ? state.formTransaction.receiver_is_company
          : template.receiver_is_company,
      };

      state.selectedFormTemplate = template.id;

      // Populate fields
      Object.keys(formTemplate)
        .forEach((fieldName) => {
          if (state.formTransaction.hasOwnProperty(fieldName)) {
            state.formTransaction[fieldName] = formTemplate[fieldName];
          }
        });
    },
    history(state, payload) {
      state.history = payload;
    },
    voucher(state, payload) {
      Vue.set(state.formTransaction, 'voucher', payload);
    },
    vouchers(state, payload) {
      state.vouchers = payload;
    },

    setSelectedFormTemplate(state, template) {
      state.selectedFormTemplate = template;
    },
  },
  actions: {
    createTransaction({ dispatch }, transaction) {
      return Transactions.createTransaction(transaction)
        .then((res) => {
          dispatch('profile/getProfile', null, { root: true });
          return res;
        });
    },
    editTransaction({ dispatch }, transaction) {
      return Transactions.editTransaction(transaction)
        .then((res) => {
          dispatch('profile/getProfile', null, { root: true });
          return res;
        });
    },
    getTransaction({ commit, dispatch }, transactionId) {
      return Transactions.getTransaction(transactionId)
        .then((transaction) => commit('transaction', transaction))
        .then(() => dispatch('initStatusPing'));
    },
    getTransactionHistory({ commit }, transactionId) {
      return Transactions.getTransactionHistory(transactionId)
        .then((history) => commit('history', history));
    },
    initStatusPing({ state }) {
      if (!Transactions.isFinalStatus(state.transaction.status)) {
        Transactions.pingStatus(state.transaction.transaction_key);
      }
    },
    stopStatusPing() {
      Transactions.stopStatusPing();
    },
    getVouchers({ state, commit }) {
      return Profile.getVouchers(state.vouchersFilter)
        .then((vouchers) => commit('vouchers', vouchers));
    },
    addVoucher({ dispatch }, voucherCode) {
      return Profile.addVoucher(voucherCode)
        .then((res) => {
          Notifications.notify('success', res.message);
          dispatch('getVouchers');
        });
    },
    useVoucher({ commit, state, dispatch }, voucherCode) {
      // Apply to existing transaction
      if (state.transaction) {
        Transactions.useVoucher(state.transaction, voucherCode)
          .then(() => {
            dispatch('getTransaction', state.transaction.transaction_key);
            dispatch('getVouchers');
          });
      } else {
        commit('voucher', voucherCode);
        app.$router.push({ name: 'payments' });
      }
      commit('isVoucherModalOpen', false);
    },
    removeVoucher({ state, dispatch }) {
      return Transactions.removeVoucher(state.transaction)
        .finally(() => {
          dispatch('getTransaction', state.transaction.transaction_key);
          dispatch('getVouchers');
        });
    },
    vouchersfilterChanged({ commit, dispatch }, payload) {
      commit('vouchersFilter', payload);
      dispatch('getVouchers');
    },
    statusUpdated({ state, dispatch }, transaction) {
      if (state.transaction && (
        state.transaction.status !== transaction.status
        || state.transaction.rate_expires !== transaction.rate_expires
        || state.transaction.rate_expires_sec !== transaction.rate_expires_sec
      )) {
        dispatch('getTransaction', state.transaction.transaction_key);
        dispatch('getTransactionHistory', state.transaction.transaction_key);
      }
    },
    resetState({ commit }) {
      commit('transaction', null);
      commit('clearTransactionForm');
      commit('history', null);
      commit('vouchers', null);
    },
    confirmTransaction({ commit, state }, params) {
      return Transactions.confirmTransaction(state.transaction, params)
        .then((transaction) => commit('transaction', transaction));
    },
    cancelTransaction({ commit, state }) {
      return Transactions.cancelTransaction(state.transaction)
        .then((transaction) => commit('transaction', transaction));
    },
  },
  getters: {
    newVouchers(state) {
      if (!state.vouchers) {
        return [];
      }
      const vouchers = [];
      state.vouchers.forEach((voucher) => {
        if (voucher.is_new) {
          vouchers.push(voucher);
        }
      });
      return vouchers;
    },
    newVouchersCount(state, getters) {
      return getters.newVouchers.length;
    },
    getTransaction(state) {
      if (!state.transaction) {
        return [];
      }
      return state.transaction;
    },
  },
};

import Vue from 'vue';
import Vuex from 'vuex';
import Auth from '../services/auth.service';
import campaigns from './campaigns';
import countries from './countries';
import coins from './coins';
import auth from './auth';
import contracts from './contracts';
import referralCode from './referralCode';
import transactions from './transactions';
import templates from './templates';
import referrals from './referrals';
import balances from './balances';
import language from './language';
import profile from './profile';
import transactionVolumes from './transaction-volumes';
import sofs from './sofs';
import sidebar from './sidebar';
import wallet from './wallet';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    campaigns,
    coins,
    contracts,
    countries,
    referralCode,
    transactions,
    templates,
    referrals,
    sidebar,
    balances,
    language,
    transactionVolumes,
    sofs,
    profile,
    wallet,
  },
  state: {
    isExplainerModalOpen: false,
  },
  mutations: {
    updateIsExplainerModalOpen(state, payload) {
      state.isExplainerModalOpen = payload;
    },
  },
  actions: {
    init({ commit, dispatch, state }) {
      commit('auth/isAuthenticated', Auth().isAuthenticated());
      dispatch('campaigns/getCampaigns');

      if (state.auth.isAuthenticated) {
        dispatch('profile/getProfile');
        dispatch('contracts/checkContract');
        dispatch('auth/checkMerge');
      }
    },
    resetState({ dispatch }) {
      dispatch('profile/resetState');
      dispatch('auth/resetState');
      dispatch('transactions/resetState');
      dispatch('templates/resetState');
      dispatch('balances/resetState');
    },
  },
  getters: {},
});

import Vue from 'vue';
import VueI18Next from '@panter/vue-i18next';
import i18next from 'i18next';

import enMessages from '@/i18n/locales/en.json';
import app from '../main';

Vue.use(VueI18Next);

const initialized = i18next.init({
  lng: 'en',
  fallbackLng: 'en',
  // whitelist: ['en', 'ru', 'de', 'fr', 'es'],
  whitelist: ['en'],
  ns: ['statuses', 'common', 'messages'],
  detection: {
    order: ['querystring', 'path', 'localStorage', 'navigator'],
    lookupQuerystring: 'lang',
    caches: ['localStorage'],
    checkWhitelist: true,
  },
  resources: {
    en: enMessages,
  },
}).then(() => app.$store.dispatch('language/initLanguage', i18next.language));

const i18n = new VueI18Next(i18next, {
  loadComponentNamespace: true,
});

i18n.initialized = initialized;

export default i18n;

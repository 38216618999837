<template>
  <div
    class="skeleton"
    :class="{ 'skeleton--loading': hasAnimation }"
  />
</template>

<script>
export default {
  name: 'VSkeleton',
  props: {
    hasAnimation: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton {
  display: flex;
  background-color: #eef0f1;
  border-radius: 5px;

  &--loading {
    position: relative;
    overflow: hidden;

    &:after {
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0) 100%);
      transform: translateX(-100%);
      animation: skeleton-loading 1.5s infinite;
      content: "";
      will-change: transform;
    }
  }
}

@keyframes skeleton-loading {
  100% {
    transform: translateX(100%);
  }
}
</style>

<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="!vouchers"
        key="loading"
        class="col-sm-4 no-padding"
      >
        <AppPlaceholder />
      </div>
      <div
        v-else
        key="loaded"
        class="vouchers-list"
      >
        <VoucherDetails
          v-for="voucher in vouchers"
          :key="voucher.code"
          :voucher="voucher"
          :show-redeem-button="showRedeemButtons"
          @use-voucher="useVoucher"
        />
      </div>
    </transition>
    <p v-if="vouchers && !vouchers.length && showEmptyState">
      {{ $t('no-vouchers-to-show') }}
    </p>
  </div>
</template>
<script>
import AppPlaceholder from '../AppPlaceholder';
import VoucherDetails from './VoucherDetails';

export default {
  name: 'VouchersList',
  i18nOptions: { namespaces: 'vouchers-list' },
  components: {
    AppPlaceholder,
    VoucherDetails,
  },

  props: {
    vouchers: {
      default: null,
    },
    showEmptyState: {
      default: true,
    },
    showRedeemButtons: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    useVoucher(voucher) {
      this.$emit('use-voucher', voucher);
    },
  },
};
</script>

export const COMPANY_OWNERSHIP_TYPE = {
  SHAREHOLDER: 'shareholder',
  UBO: 'ubo',
  PEP: 'pep',
};

export const COMPANY_ENTITY_TYPE = {
  PRIVATE_PERSON: 'private',
  LEGAL_ENTITY: 'legal_entity',
};

export const COMPANY_UBO_CONTROL_TYPE = {
  DIRECT: 'direct',
  INDIRECT: 'indirect',
};

export const PROFILE_TYPE = {
  PERSONAL: 1,
  COMPANY: 2,
};

export const PROFILE_STATUS = {
  INCOMPLETE: 'incomplete',
  PENDING: 'pending',
  COMPLETE: 'complete',
  NO_SERVICE: 'no_service',
};

export const PROFILE_SECTION = {
  PERSONAL: 'personal',
  IDENTITY: 'identity',
  ADDITIONAL: 'additional',
  CONFIRM: 'confirm',
};

export const COMPANY_PROFILE_SECTION = {
  COMPANY: 'company',
  COMPANY_ACTIVITY: 'company-activity',
  COMPANY_IDENTITY: 'company-identity',
  COMPANY_OWNERSHIP: 'company-ownership',
  COMPANY_ADDITIONAL: 'company-additional',
  COMPANY_CONFIRM: 'company-confirm',
};

export const TRANSACTION_STATUS = {
  NEW: 'N',
  INCOMPLETE: 'I',
  PROCESSING: 'P',
  COMPLETED: 'C,S,Z',
  RETURNED: 'R',
  CANCELLED: 'X',
};

export const TRANSACTION_TYPE = {
  INSTA_PAY: 0,
  INSTA_FILL: 1,
};

export const DEFAULT_TEMPLATE_ID = 'new';

export const AUTH_POPUP_KEY = 'auth-popup';

export const CURRENCY = {
  EUR: 'EUR',
  GBP: 'GBP',
};

export const GBP_DESCRIPTION_LENGTH = 13;

export const VERIFICATION_LEVEL = {
  STARTER: 1,
  BASIC: 2,
  FULL: 3,
};

// TODO: get from API !?
export const TRANSACTION_PURPOSES = [
  { id: 'paying_bills_taxes_salaries', name: 'Paying bills, taxes, or salaries with crypto' },
  { id: 'sending_money_to_bank_accounts', name: 'Sending money to various bank accounts' },
  { id: 'converting_crypto_to_fiat', name: 'Converting crypto-to-fiat by using SwapinGet' },
  { id: 'buying_crypto', name: 'Buying crypto' },
];

export const SWAPIN_COLLECT_URL = 'https://secure.swapin.com';

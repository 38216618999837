import Vue from 'vue';
import { ethers } from 'ethers';

import { CHAINS } from '@/web3/chains';
import { ERC20_ABI } from '@/web3/abis';
import { getWalletById } from '@/web3/wallets';
import Track from '@/services/track.service';

// KNOWS ERROR CODES
// -32002 | Already processing eth_requestAccounts. Please wait.

const CACHED_PROVIDER_KEY = 'WEB3_CACHED_PROVIDER';

const state = Vue.observable({
  isConnected: false,
  address: '',
  chainId: '',
  coins: [],
  assets: [],
  isAssetsLoading: true,
});

class Web3Wallet {
  web3Provider = null;

  signer = null;

  connectedWallet = null;

  cachedProvider = '';

  get isConnected() {
    return state.isConnected;
  }

  get address() {
    return state.address;
  }

  get chainId() {
    return state.chainId;
  }

  get coins() {
    return state.coins;
  }

  get isAssetsLoading() {
    return state.isAssetsLoading;
  }

  get assets() {
    return state.assets;
  }

  get assetsCurrencyBalance() {
    return this.assets.reduce((sum, item) => sum + item.balanceCurrency, 0);
  }

  get chain() {
    const chain = CHAINS.find(({ id }) => id === this.chainId);

    return chain;
  }

  get wallet() {
    const { walletMeta, isWalletConnect } = this.web3Provider?.provider || {};

    return {
      ...this.connectedWallet,
      meta: walletMeta ? { name: walletMeta.name } : {},
      isWalletConnect,
    };
  }

  clearCachedProvider() {
    this.cachedProvider = '';
    window.localStorage.removeItem(CACHED_PROVIDER_KEY);
  }

  setCachedProvider(id) {
    this.cachedProvider = id;
    window.localStorage.setItem(CACHED_PROVIDER_KEY, id);
  }

  setCoins(coins) {
    state.coins = coins;

    this.calculateAssets();
  }

  parseChainId(id) {
    return typeof id === 'string' ? id : ethers.utils.hexValue(id);
  }

  async init() {
    const cachedProviderKey = window.localStorage.getItem(CACHED_PROVIDER_KEY) || '';
    const wallet = getWalletById(cachedProviderKey);

    if (!wallet || !wallet.isInstalled()) {
      return;
    }

    const provider = await wallet.getProvider();

    this.setCachedProvider(wallet.id);
    await this.onProviderConnected(wallet, provider);
  }

  async onConnect(event) {
    state.isConnected = true;
    state.chainId = this.parseChainId(event.chainId);

    state.address = await this.getAddress();

    this.calculateAssets();
  }

  onDisconnect() {
    this.resetState();
  }

  onAccountsChanged(accounts) {
    if (accounts.length > 0) {
      state.isConnected = true;
      state.address = accounts[0];
      state.chainId = this.parseChainId(this.web3Provider.provider.chainId);

      this.calculateAssets();
    } else {
      this.resetState();
    }
  }

  async onChainChanged(chainId) {
    const id = this.parseChainId(chainId);

    if (state.chainId === id) {
      return
    }

    // FIXME: move to vue file
    Track.send('web3-wallet-chain-change', { id });

    state.chainId = id;

    if (!this.address) {
      state.address = await this.getAddress();
    }

    this.calculateAssets();
  }

  resetState() {
    state.isConnected = false;
    state.address = '';
    state.chainId = '';
    state.assets = [];
  }

  removeAllProviderListeners() {
    if (!this.web3Provider) {
      return;
    }

    const { provider } = this.web3Provider;

    if (provider.isWalletConnect) {
      provider.events.removeAllListeners();
      return;
    }

    provider.removeAllListeners();
  }

  async onProviderConnected(wallet, provider) {
    this.web3Provider = new ethers.providers.Web3Provider(provider, 'any');
    this.signer = await this.web3Provider.getSigner();

    const address = await this.getAddress();

    if (!address) {
      return;
    }

    this.connectedWallet = wallet;

    state.address = address;
    state.chainId = await this.getChainId();
    state.isConnected = true;

    this.calculateAssets();

    this.web3Provider.provider.on('connect', (_event) => this.onConnect(_event));
    this.web3Provider.provider.on('disconnect', (_event) => this.onDisconnect(_event));
    this.web3Provider.provider.on('accountsChanged', (_event) => this.onAccountsChanged(_event));
    this.web3Provider.provider.on('chainChanged', (_event) => this.onChainChanged(_event));
  }

  async connect(wallet) {
    return new Promise(async (resolve, reject) => {
      if (!wallet.isInstalled()) {
        reject(new Error('wallet does not installed'));
      }

      const provider = await wallet.getProvider();

      if (!provider) {
        reject(new Error('provider not found'));
      }

      const handleConnect = async () => {
        this.setCachedProvider(wallet.id);
        await this.onProviderConnected(wallet, provider);

        resolve();
      };

      if (provider.isConnecting || provider.connecting || provider.connected) {
        await provider.disconnect();
      }

      if (provider.isWalletConnect) {
        provider.on('connect', async () => {
          handleConnect();
        });

        provider.connect();
      } else {
        provider.request({ method: 'eth_requestAccounts' })
          .then(() => {
            handleConnect();
          })
          .catch(() => {});
      }
    });
  }

  async disconnect() {
    this.removeAllProviderListeners();

    if (this.web3Provider.provider.disconnect) {
      await this.web3Provider.provider.disconnect();
    }

    this.clearCachedProvider();
    this.onDisconnect();
  }

  async getAddress() {
    // this.address = await this.signer.getAddress();
    const accounts = await this.web3Provider.send('eth_accounts');

    return accounts[0];
  }

  async getChainId() {
    const id = await this.web3Provider.send('eth_chainId');

    return this.parseChainId(id);
  }

  async calculateAssets(silent) {
    if (!this.coins || this.coins.length === 0 || !this.address) {
      return;
    }

    if (!silent) {
      state.isAssetsLoading = true;
    }

    const promises = this.coins.map((coin) => {
      return this.getCoinBalance(coin).then((balance) => {
        if (balance <= 0) {
          return null;
        }

        return Object.freeze({
          coin: coin.coin,
          name: coin.name,
          balance,
          balanceCurrency: coin.rates.EUR * balance,
        });
      });
    });

    const assets = (await Promise.all(promises)).filter(Boolean);

    state.assets = assets;
    state.isAssetsLoading = false;
  }

  async selectChain(chainId) {
    if (this.isWalletConnect || chainId === this.chainId) {
      return;
    }

    try {
      await this.web3Provider.send('wallet_switchEthereumChain', [{ chainId }]);
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      // if (error.code === 4902) {
      //   this.web3Provider.send('wallet_addEthereumChain', [{
      //     chainName: 'Polygon Mainnet',
      //     chainId: web3.utils.toHex(chainId),
      //     nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
      //     rpcUrls: ['https://polygon-rpc.com/']
      //   }]);
      // }
    }
  }

  async getCoinBalance(coin) {
    let balance = 0;
    let decimals = 18;

    if (coin.chain_id === this.chainId) {
      try {
        if (this.chain.nativeCurrency.symbol === coin.coin) {
          balance = await this.web3Provider.getBalance(this.address);
        }

        if (coin.contract) {
          const contract = new ethers.Contract(coin.contract, ERC20_ABI, this.web3Provider);
          const contractDecimals = await contract.decimals();

          if (contractDecimals) {
            decimals = contractDecimals.toNumber();
          }

          balance = await contract.balanceOf(this.address);
        }
      } catch (error) {
        // catch
      }
    }

    return ethers.utils.formatUnits(balance, decimals);
  }

  async sendTransaction(to, value, coin) {
    try {
      if (this.chain.nativeCurrency.symbol === coin.coin) {
        return await this.signer.sendTransaction({
          to,
          value: ethers.utils.parseEther(value.toString()),
        }).then((tx) => {
          tx.wait().then(() => this.calculateAssets(true));

          return tx;
        });
      }

      const contract = new ethers.Contract(coin.contract, ERC20_ABI, this.signer);
      const contractDecimals = await contract.decimals();

      const decimals = contractDecimals ? contractDecimals.toNumber() : 18;
      const amount = ethers.utils.parseUnits(value.toString(), decimals);

      return await contract.transfer(to, amount).then((tx) => {
        contract.once('Transfer', () => this.calculateAssets(true));

        return tx;
      });
    } catch (error) {
      // catch
    }
  }
}

export default new Web3Wallet();

import { PROFILE_TYPE } from '@/const';

import Auth from '../services/auth.service';
import app from '../main';

export default {
  namespaced: true,
  state: {
    isPasswordResetRequestModalOpen: false,
    isAuthenticated: null,
    mergeAccounts: null,
  },
  mutations: {
    isPasswordResetRequestModalOpen(state, payload) {
      state.isPasswordResetRequestModalOpen = payload;
    },
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    resetMergeAccounts(state) {
      state.mergeAccounts = null;
    },
    mergeAccounts(state, payload) {
      state.mergeAccounts = [payload.current_user, payload.secondary_user];
    },
  },
  actions: {
    authenticate({ dispatch, rootGetters }, payload) {
      const data = {};
      const referralCode = rootGetters['referralCode/referralCode'];
      const referralUrl = rootGetters['referralCode/referralUrl'];

      if (referralCode) {
        data.referral_code = referralCode;
      }
      if (referralUrl) {
        data.referral_url = referralUrl;
      }

      return Auth(payload.configType).authenticate(payload.provider, data)
        .then((res) => {
          if (res.data.hasOwnProperty('status') && res.data.status === 'merge_possible') {
            dispatch('promptMerge', res.data);
            return Promise.reject();
          }

          return res;
        });
    },

    checkMerge({ dispatch }) {
      return Auth().checkMerge()
        .then((res) => {
          if (res.hasOwnProperty('status') && res.status === 'merge_possible') {
            dispatch('promptMerge', res);
          }

          return res;
        });
    },
    login(store, user) {
      return Auth().login(user);
    },
    signup({ rootGetters }, user) {
      const referralCode = rootGetters['referralCode/referralCode'];
      const referralUrl = rootGetters['referralCode/referralUrl'];

      if (referralCode) {
        user.referral_code = referralCode;
      }

      if (referralUrl) {
        user.referral_url = referralUrl;
      }

      return Auth().register(user);
    },
    authSuccess({ commit, dispatch }, { response }) {
      commit('isAuthenticated', true);
      dispatch('redirect', response);
      dispatch('profile/getProfile', null, { root: true });
      dispatch('contracts/checkContract', null, { root: true });
      dispatch('auth/checkMerge', null, { root: true });
    },
    logout({ commit, dispatch }, redirect) {
      Auth().logoutAll();
      commit('isAuthenticated', false);
      dispatch('resetState', null, { root: true });

      app.$router.push({
        name: 'login',
        ...(redirect ? { query: { redirect } } : {}),
      });
    },
    promptMerge({ commit }, payload) {
      commit('mergeAccounts', payload);
      app.$router.push('/merge-accounts');
    },
    merge(store, payload) {
      return Auth().merge(payload.close_user_token, payload.primary_user_token);
    },
    redirect(store, payload) {
      const { profile_completeness, user } = payload.data;
      const startRouteName = user.type === PROFILE_TYPE.PERSONAL || (!user.is_verify_required && profile_completeness === 100) ? 'overview' : 'profile';
      const route = app.$route.query.redirect || { name: startRouteName };

      return app.$router.replace(route);
    },
    resetState({ commit }) {
      commit('resetMergeAccounts');
    },
  },
};

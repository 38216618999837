import http from './http.service';

class Uploads {
  uploadFile(file, type, properties) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    for (const [key, value] of Object.entries(properties || {})) {
      formData.append(key, value);
    }

    return http.post('files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((res) => res.data);
  }

  deleteFile(id) {
    return http.delete(`files/${id}`)
      .then((res) => res.data);
  }

  cancelFile(id) {
    return http.get(`files/${id}/cancel`)
      .then((res) => res.data);
  }
}

export default new Uploads();

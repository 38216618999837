import http from './http.service';

const canTrack = process.env.NODE_ENV === 'production';

class Track {
  send(event, properties) {
    if (canTrack) {
      http.post('mixpanel/track', { event, properties });
    }
  }
}

export default new Track();

import Vue from 'vue';

class Events {
  constructor() {
    this.vue = new Vue();
  }

  emit(event, data = null) {
    this.vue.$emit(event, data);
  }

  on(event, callback) {
    this.vue.$on(event, callback);
  }

  off(event, handler) {
    this.vue.$off(event, handler);
  }
}

export default new Events();

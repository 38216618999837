<template>
  <Modal
    :value="isOpen"
    :footer="false"
    @hide="hide"
  >
    <div slot="header">
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="hide"
      >
        <i
          class="material-icons"
          aria-hidden="true"
        >close</i>
      </button>
      <div
        v-if="isWalletConnected"
        class="d-flex align-items-center flex-wrap"
      >
        <div class="wallet-title mr-3">
          <img
            :src="`/img/wallets/wallet-${$options.Web3Wallet.wallet.id}.svg`"
            height="32"
            class="mr-2"
          >
          <h3 class="modal-title">
            {{ $t('your-wallet') }}
          </h3>
        </div>
      </div>
      <h3
        v-else
        class="modal-title"
      >
        {{ $t('connect-to-your-wallet') }}
      </h3>
    </div>

    <template v-if="isWalletConnected">
      <div class="form-section-titlegroup mb-1">
        <h5 class="form-section-title">
          {{ $t('address') }}
        </h5>
        <button
          v-send-event:click="{
            target: 'web3-wallet-disconnect',
            walletId: $options.Web3Wallet.wallet.id,
            walletMeta: $options.Web3Wallet.wallet.meta,
          }"
          class="btn btn-gray btn-sm py-1 h-auto"
          @click="$options.Web3Wallet.disconnect()"
        >
          {{ $t('disconnect') }}
        </button>
      </div>

      <div class="d-flex align-items-center">
        <span class="text-gray fs-lg break-all">
          {{ $options.Web3Wallet.address }}
        </span>
        <button
          v-tooltip.hover="$t('copy-address')"
          class="btn btn-icon btn-light px-1 ml-1"
          @click="copyToClipboard($options.Web3Wallet.address)"
        >
          <CopyIcon />
        </button>
        <a
          v-if="addressExplorerUrl"
          v-tooltip.hover="$t('view-in-explorer')"
          :href="addressExplorerUrl"
          target="_blank"
          rel="noopener noreferrer"
          class="btn btn-icon btn-light px-1"
        >
          <ExternalIcon />
        </a>
      </div>

      <template v-if="!$options.Web3Wallet.wallet.isWalletConnect">
        <h5 class="form-section-title mt-5 mb-2">
          {{ $t('select-network') }}
        </h5>

        <div
          v-if="!isSupportedChain"
          class="alerts alerts--danger py-2 px-3 mb-2"
        >
          <i class="material-icons md-24 mr-2">error</i>
          <div>
            {{ $t('network-not-supported') }}
          </div>
        </div>
      </template>

      <template v-if="$options.Web3Wallet.wallet.isWalletConnect">
        <div class="d-flex align-items-center mt-2">
          <div v-if="isSupportedChain" class="mr-2">
            <span class="fs-sm text-gray">Network:</span><br>
            <span class="text-nowrap">{{ currentNetworkName }}</span>
          </div>
          <div
            v-if="!isSupportedChain"
            class="alerts alerts--danger alerts--borderless flex-column align-items-start py-2 px-2"
          >
            <div class="mb-2">
              {{ $t('network-not-supported-walletconnect') }}:
            </div>
            <ul>
              <li
                v-for="chain in $options.CHAINS"
                :key="chain.id"
              >
                {{ chain.name }}
              </li>
            </ul>
          </div>
          <div v-else class="alerts alerts--info alerts--borderless py-1 px-2">
            <p class="my-0">
              {{ $t('select-network-from-wallet') }}
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="networks-list mb-5">
          <div
            v-for="chain in $options.CHAINS"
            :key="chain.id"
            v-send-event:click="{
              target: 'web3-wallet-chain-select',
              value: chain.id,
            }"
            class="networks-list__item"
            :class="{
              'networks-list__item--selected': chain.id === $options.Web3Wallet.chainId,
            }"
            @click="onNetworkClick(chain.id)"
          >
            <div class="networks-list__item-icon">
              <img :src="coinImg(chain.nativeCurrency.symbol)">
            </div>
            <div class="networks-list__item-label">
              {{ chain.name }}
            </div>
            <div class="networks-list__item-indicator" />
          </div>
        </div>
      </template>

      <template v-if="isSupportedChain">
        <div class="d-flex mt-4">
          <h5 class="form-section-title flex-grow-1">
            {{ $t('your-assets') }}
          </h5>
          <h5 class="form-section-title text-right">
            <template v-if="isAssetsLoading">
              <VSkeleton class="skeleton-total" />
            </template>
            <template v-else>
              ≈ {{ formatMoney($options.Web3Wallet.assetsCurrencyBalance, 'EUR', 2) }}
            </template>
          </h5>
        </div>

        <div class="assets-list mb-3">
          <div
            v-if="isAssetsLoading"
            class="mb-3"
          >
            <div class="assets-list__item">
              <div class="assets-list__item-icon">
                <VSkeleton class="skeleton-coin" />
              </div>
              <div class="assets-list__item-content">
                <div class="assets-list__item-balance">
                  <VSkeleton class="skeleton-title mb-1" />
                </div>
                <div class="assets-list__item-description">
                  <VSkeleton class="skeleton-description" />
                </div>
              </div>
            </div>
          </div>
          <template v-else>
            <div
              v-if="$options.Web3Wallet.assets.length === 0"
              class="text-center mt-4 mb-3"
            >
              <div class="circle-bg-icon mb-3">
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                ><path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1 2.5a.5.5 0 0 1 .5.5c0 .246.059.477.163.681a.5.5 0 0 1-.163.64V4.7a.5.5 0 0 1-1 0V3a.5.5 0 0 1 .5-.5Zm.646 2.055a.5.5 0 0 1 .673-.218c.204.104.435.163.681.163h1.35a.5.5 0 0 1 0 1H3c-.408 0-.794-.098-1.136-.272a.5.5 0 0 1-.218-.673ZM5.65 5a.5.5 0 0 1 .5-.5h2.7a.5.5 0 0 1 0 1h-2.7a.5.5 0 0 1-.5-.5Zm4.5 0a.5.5 0 0 1 .5-.5h2.7a.5.5 0 0 1 0 1h-2.7a.5.5 0 0 1-.5-.5Zm4.5 0a.5.5 0 0 1 .5-.5h2.7a.5.5 0 0 1 0 1h-2.7a.5.5 0 0 1-.5-.5Zm4.5 0a.5.5 0 0 1 .5-.5H21a.5.5 0 0 1 .5.5v2.1a.5.5 0 0 1-1 0V5.5h-.85a.5.5 0 0 1-.5-.5ZM1 6.467a.5.5 0 0 1 .5.5v3.4a.5.5 0 0 1-1 0v-3.4a.5.5 0 0 1 .5-.5ZM21 9.4a.5.5 0 0 1 .5.5V12a.5.5 0 0 1-1 0V9.9a.5.5 0 0 1 .5-.5ZM1 12.133a.5.5 0 0 1 .5.5v3.4a.5.5 0 1 1-1 0v-3.4a.5.5 0 0 1 .5-.5ZM21 15.5a.5.5 0 0 1 .5.5v2.1a.5.5 0 0 1-1 0V16a.5.5 0 0 1 .5-.5ZM1 17.8a.5.5 0 0 1 .5.5V20c0 .41.098.795.272 1.135a.5.5 0 1 1-.891.455A3.487 3.487 0 0 1 .5 20v-1.7a.5.5 0 0 1 .5-.5Zm20 2.6a.5.5 0 0 1 .5.5V23a.5.5 0 0 1-.5.5h-1.7a.5.5 0 0 1 0-1h1.2v-1.6a.5.5 0 0 1 .5-.5ZM2.192 22.446a.5.5 0 0 1 .673-.218c.34.174.725.272 1.135.272h1.7a.5.5 0 0 1 0 1H4a3.489 3.489 0 0 1-1.59-.381.5.5 0 0 1-.218-.673ZM7.467 23a.5.5 0 0 1 .5-.5h3.4a.5.5 0 0 1 0 1h-3.4a.5.5 0 0 1-.5-.5Zm5.666 0a.5.5 0 0 1 .5-.5h3.4a.5.5 0 1 1 0 1h-3.4a.5.5 0 0 1-.5-.5Z"
                  fill="#787F8C"
                /><path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3 1.5c-.246 0-.477.059-.681.163a.5.5 0 0 1-.455-.89A2.492 2.492 0 0 1 3 .5h1.4a.5.5 0 0 1 0 1H3ZM5.767 1a.5.5 0 0 1 .5-.5h2.8a.5.5 0 0 1 0 1h-2.8a.5.5 0 0 1-.5-.5Zm4.666 0a.5.5 0 0 1 .5-.5h2.8a.5.5 0 1 1 0 1h-2.8a.5.5 0 0 1-.5-.5ZM15.1 1a.5.5 0 0 1 .5-.5H17a.5.5 0 0 1 .5.5v.3a.498.498 0 0 1-.042.2.498.498 0 0 1 .042.2V2a.5.5 0 0 1-1 0v-.3c0-.071.015-.139.042-.2H15.6a.5.5 0 0 1-.5-.5Zm-13.655.646a.5.5 0 0 1 .218.673A1.493 1.493 0 0 0 1.5 3c0 .246.059.477.163.681a.5.5 0 0 1-.89.455A2.492 2.492 0 0 1 .5 3c0-.408.098-.794.272-1.136a.5.5 0 0 1 .673-.218Zm.201 2.91a.5.5 0 0 1 .673-.219c.204.104.435.163.681.163a.5.5 0 0 1 0 1c-.408 0-.794-.098-1.136-.272a.5.5 0 0 1-.218-.673ZM18 12.5c-.246 0-.477.059-.681.163l-.434.221-.222.435A1.493 1.493 0 0 0 16.5 14c0 .246.059.477.163.681l.221.435.435.221c.204.104.435.163.681.163h2v1h-2c-.408 0-.794-.098-1.136-.272L16.42 16l.438-.858-.858.438-.227-.445A2.493 2.493 0 0 1 15.5 14c0-.408.098-.794.272-1.136L16 12.42l.857.438-.437-.858.445-.227A2.493 2.493 0 0 1 18 11.5h2v1h-2Zm3-1h2a.5.5 0 0 1 .5.5v1.7h-1v-1.2H21v-1Zm2.5 2.8V16a.5.5 0 0 1-.5.5h-2v-1h1.5v-1.2h1Z"
                  fill="#787F8C"
                /></svg>
              </div>
              <p class="fs-lg mb-1">
                <strong>{{ $t('no-assets-title') }}</strong>
              </p>
              <p class="text-gray mb-4">
                {{ $t('no-assets-description') }}
              </p>
              <button
                v-if="false"
                class="btn btn-default"
              >
                {{ $t('buy-crypto') }}
              </button>
            </div>
            <template v-else>
              <div
                v-for="asset in $options.Web3Wallet.assets"
                :key="asset.coin"
                class="assets-list__item"
              >
                <div class="assets-list__item-icon">
                  <img :src="coinImg(asset.coin)">
                </div>
                <div class="assets-list__item-content">
                  <div class="assets-list__item-balance">
                    {{ asset.balance }} {{ asset.coin }} <span class="assets-list__item-balance--fiat">≈ {{ formatMoney(asset.balanceCurrency, 'EUR', 2) }}</span>
                  </div>
                  <div class="assets-list__item-description">
                    {{ asset.name }}
                  </div>
                </div>
                <!-- <div class="actions">
                  <button class="btn btn-default">
                    {{ $t('buy') }}
                  </button>
                </div> -->
              </div>
            </template>
          </template>
        </div>
      </template>
      <div class="visible-xs-block text-center mt-3">
        <button
          type="button"
          class="btn btn-primary px-6"
          @click="hide"
        >
          {{ $t('common:close') }}
        </button>
      </div>
    </template>
    <template v-else>
      <p class="lead text-gray mb-4">
        {{ $t('select-wallet') }}
      </p>

      <div
        v-if="hasSelectedWalletInstallError"
        class="alerts alerts--danger py-2 px-3 mb-2"
      >
        <i class="material-icons md-24 mr-2">error</i>
        <div>
          {{ $t('wallet-no-access') }}
        </div>
      </div>

      <div class="providers-list">
        <div
          v-for="wallet in $options.WALLETS"
          :key="wallet.id"
          class="providers-list__item"
          :class="{ 'providers-list__item--disabled': wallet.isComingSoon }"
          @click="onProviderClick(wallet)"
        >
          <div class="providers-list__item-icon">
            <img
              :src="wallet.imageSrc"
              height="40"
              :alt="wallet.name"
            >
          </div>
          <div class="providers-list__item-title">
            {{ wallet.name }}
          </div>
          <div
            v-if="wallet.isComingSoon"
            class="providers-list__item-badge"
          >
            {{ $t('coming-soon') }}
          </div>
        </div>
      </div>

      <p class="text-gray mt-5 mb-3">
        <a
          href="https://help.swapin.com/en/articles/341009-connect-wallet-how-to-use-web3-wallet-integration"
          target="_blank"
        >{{ $t('learn-more') }}</a> {{ $t('about-wallet-benefits') }}
      </p>

      <p class="text-gray-medium mt-6 fs-sm">
        {{ $t('wallet-disclaimer') }}
      </p>
    </template>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import { Modal, tooltip } from 'uiv';

import sendEvent from '@/utils/directives/sendEvent';
import { mobileAndTabletCheck } from '@/utils/helpers';
import { copyToClipboard } from '@/utils/clipboard';
import { formatMoney } from '@/utils/currency';
import Track from '@/services/track.service';
import Coins from '@/services/coins.service';
import Web3Wallet from '@/services/web3Wallet.service';
import CopyIcon from '@/components/icons/CopyIcon.vue';
import ExternalIcon from '@/components/icons/ExternalIcon.vue';
import { VSkeleton } from '@/components/ui/Skeleton';

import { WALLETS } from '@/web3/wallets';
import { CHAINS } from '@/web3/chains';

const IS_MOBILE = mobileAndTabletCheck();

export default {
  name: 'WalletModal',
  i18nOptions: { namespaces: ['wallet', 'common'] },

  components: {
    CopyIcon,
    ExternalIcon,
    Modal,
    VSkeleton,
  },

  directives: {
    tooltip,
    sendEvent,
  },

  data() {
    return {
      hasSelectedWalletInstallError: false,
    };
  },
  Web3Wallet,
  WALLETS,
  CHAINS,

  computed: {
    ...mapState({
      isOpen: (state) => state.wallet.isWalletModalOpen,
      openedFrom: (state) => state.wallet.openedFrom,
      profile: (state) => state.profile.profile,
    }),

    isWalletConnected() {
      return Web3Wallet.isConnected;
    },

    isSupportedChain() {
      return CHAINS.some(({ id }) => id === Web3Wallet.chainId);
    },

    isAssetsLoading() {
      return Web3Wallet.isAssetsLoading;
    },

    addressExplorerUrl() {
      const { chain, address } = Web3Wallet;

      return chain ? `${chain.explorerUrl}/address/${address}` : '';
    },

    currentNetworkName() {
      const { chainId } = Web3Wallet;

      return CHAINS.find(({ id }) => id === chainId)?.name;
    },
  },

  methods: {
    copyToClipboard,
    formatMoney,

    hide() {
      this.$store.dispatch('wallet/hideWalletModal');
    },

    onProviderClick(wallet) {
      const isWalletInstalled = wallet.isInstalled();
      this.hasSelectedWalletInstallError = !isWalletInstalled;

      if (isWalletInstalled) {
        Web3Wallet.connect(wallet).then(() => {
          Track.send('web3-wallet-connect', {
            walletId: wallet.id,
            walletMeta: Web3Wallet.wallet.meta,
            chainId: Web3Wallet.chainId,
            userId: this.profile.id,
            layout: IS_MOBILE ? 'mobile' : 'desktop',
            source: this.openedFrom,
          });
        });
      }
    },

    onNetworkClick(chainId) {
      Web3Wallet.selectChain(chainId);
    },

    coinImg(c) {
      return Coins.coinImg(c);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/sass/variables";

.wallet-title {
  display: flex;
  align-items: center;

  @media (max-width: $screen-md) {
    flex-basis: 100%;
    flex-grow: 1;
  }
}

.providers-list {
  display: grid;
  gap: $gap-5;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: $screen-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  &__item {
    $item: &;

    position: relative;
    border: 1px solid $color-lighter;
    border-radius: $border-radius-base;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $gap-5;
    text-align: center;
    transition: border-color 150ms ease;
    overflow: hidden;

    &:hover {
      cursor: pointer;
      border-color: $color-primary-light;
      border-width: 2px;
      margin: -1px;
    }

    &-icon {
      margin-bottom: $gap-3;
    }

    &-title {
      font-weight: 500;
      font-size: $font-size-lg;
    }

    &-badge {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: $font-size-sm;
      text-transform: uppercase;
      font-weight: 500;
      color: $brand-warning;
      background-color: $brand-warning-light;
      border-radius: 2px;
      padding: 4px 4px;
      width: 100%;
    }

    &--disabled {
      pointer-events: none;
      border-color: $color-neutral-light;

      * {
        opacity: .5;
      }

      #{$item}-badge {
        opacity: 1;
      }

      #{$item}-title {
        opacity: .25;
      }

      img {
        filter: grayscale(1);
      }
    }
  }
}

.networks-list {
  display: grid;
  gap: $gap-5;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: $screen-md) {
    grid-template-columns: repeat(1, 1fr);
  }

  &__item {
    $item: &;

    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid $color-lighter;
    flex-grow: 1;
    border-radius: $border-radius-base;
    padding: $gap-5 $gap-6;
    transition: border-color 150ms ease, background-color 150ms ease;

    &:hover {
      cursor: pointer;
      border-color: $color-primary-light;
      border-width: 2px;
      margin: -1px;
    }

    &--selected {
      border-color: $color-primary-light;
      border-width: 2px;
      margin: -1px;
      background-color: $color-primary-50;
    }

    &-icon {
      margin-right: $gap-3;
      flex-shrink: 0;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }

    &-label {
      font-size: $font-size-base;
      color: $gray-base;
      font-weight: 400;
      margin: 0 $gap-3 0 0;
      padding: 0;
      white-space: unset;
      text-align: left;
      flex-grow: 1;
      line-height: 1.3;
    }

    &-indicator {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 1px solid $color-lighter;
      background-color: $color-white;
      transition: border-color 150ms ease, background-color 150ms ease;

      #{$item}--selected & {
        background-color: $color-primary;
        border-color: $color-primary;
        background-image: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2012%2012%22%3E%3Cpath%20d%3D%22M11.25%203.5c0%20.21-.094.398-.234.54l-6%206a.727.727%200%200%201-.516.21.743.743%200%200%201-.54-.21l-3-3a.742.742%200%200%201-.21-.54.74.74%200%200%201%20.75-.75c.188%200%20.375.094.516.234L4.5%208.445l5.46-5.46a.763.763%200%200%201%20.54-.235c.398%200%20.75.328.75.75Z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
        background-size: 12px 12px;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
}

.assets-list {
  &__item {
    display: flex;
    align-items: center;
    padding: $gap-5 0;
    border-bottom: 1px solid $color-neutral-light;

    &-icon {
      img {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }
    }

    &-content {
      flex-grow: 1;
      margin: 0 $gap-5;
    }

    &-balance {
      font-size: $font-size-xl;

      &--fiat {
        color: $color-gray;
        white-space: nowrap;
      }
    }

    &-description {
      font-size: $font-size-sm;
      color: $color-gray;
    }
  }
}

.skeleton {
  &-total {
    width: 100px;
    height: 20px;
  }

  &-coin {
    height: 32px;
    width: 32px;
    border-radius: 24px;
  }

  &-title {
    width: 220px;
    height: 22px;
  }

  &-description {
    width: 100px;
    height: 14px;
  }
}
</style>

import throttle from 'lodash.throttle';
import Countries from '../services/countries.service';

export default {
  namespaced: true,

  state: {
    all: [],
  },

  mutations: {
    getCountriesSuccess(state, payload) {
      state.all = payload;
    },
  },

  actions: {
    getCountries: throttle(
      ({ commit, state }) => (
        state.all.length > 0
          ? new Promise((resolve) => resolve(state.all))
          : Countries.getCountries()
      ).then((countries) => commit('getCountriesSuccess', countries)),
      500, { trailing: false },
    ),
  },

  getters: {
    countriesByCode: (state) => state.all.reduce((obj, item) => {
      obj[item.code] = item;
      return obj;
    }, {}),

    getCountryNameByCode: (state, getters) => (code) => {
      const country = getters.countriesByCode[code];

      return country ? country.name : '';
    },
  },
};

import Campaigns from '../services/campaigns.service';

export default {
  namespaced: true,
  state: {
    campaign: null,
  },
  mutations: {
    updateCampaign(state, campaign) {
      state.campaign = campaign;
    },
  },
  actions: {
    getCampaigns({ commit }) {
      Campaigns
        .getCampaigns()
        .then((campaigns) => {
          if (campaigns.length) {
            commit('updateCampaign', campaigns);
          }
        });
    },
  },
  getters: {
    campaignCode(state) {
      if (state.campaign) {
        return state.campaign[0].code.toLowerCase();
      }
    },
    campaignMessage(state) {
      if (state.campaign) {
        return state.campaign[0].message;
      }
    },
  },
};

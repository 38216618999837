import Vue from 'vue';
import VueRouter from 'vue-router';

import app from '../main';
import i18n from './i18n.config';

Vue.use(VueRouter);

let currentRouteMeta = null;

const DEFAULT_TITLE = 'Instant Transfers Between Crypto and Banks';
const TITLE_SUFFIX = ' | Swapin';

function manageMetaTags() {
  currentRouteMeta.meta.metaTags.map((tagDef) => {
    if (!document.querySelector(`meta[name="${tagDef.name}"]`)) {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      return tag;
    }
    return null;
  }).forEach((tag) => {
    if (tag) {
      document.head.appendChild(tag);
    }
  });
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      redirect: '/overview',
      component: () => import(/* webpackChunkName: "Dashboard" */'../views/Dashboard.vue'),
      children: [
        {
          path: '/confirm-email-address',
          name: 'confirm-email-address',
          component: () => import(/* webpackChunkName: "Payments" */'@/views/ConfirmEmailAddressPage.vue'),
        },
        {
          path: '/payments',
          name: 'payments',
          component: () => import(/* webpackChunkName: "Payments" */'../components/DashboardPayments.vue'),
        },
        {
          path: '/swapinget',
          name: 'swapinget',
          component: () => import(/* webpackChunkName: "Payments" */'../components/DashboardInstafillAddresses.vue'),
        },
        {
          path: '/swapinget/new',
          name: 'new-swapinget',
          component: () => import(/* webpackChunkName: "Payments" */'../components/DashboardInstaFill.vue'),
        },
        {
          path: '/swapinget/:id/edit',
          name: 'edit-swapinget',
          component: () => import(/* webpackChunkName: "Payments" */'../components/DashboardInstaFill.vue'),
        },
        {
          path: '/predefined',
          name: 'predefined',
          component: () => import(/* webpackChunkName: "Payments" */'../components/DashboardPredefinedPayments.vue'),
        },
        {
          path: '/overview',
          name: 'overview',
          component: () => import(/* webpackChunkName: "Overview" */'../components/DashboardOverview.vue'),
          meta: {
            title: 'Dashboard Overview',
            metaTags: [
              {
                name: 'description',
                content: 'Welcome to the Swapin dashboard, where you can send crypto and receive fiat by SwapinPay and SwapinGet tools. Buy crypto feature coming soon!',
              },
            ],
            canonicalLink: '/overview',
          },
        },
        {
          path: '/accounts',
          name: 'accounts',
          redirect: '/overview',
          component: () => import(/* webpackChunkName: "Accounts" */'../components/DashboardAccounts.vue'),
        },
        {
          path: '/rewards',
          name: 'rewards',
          component: () => import(/* webpackChunkName: "Rewards" */'../components/DashboardRewards.vue'),
        },
        {
          path: '/business-rewards',
          name: 'business-rewards',
          component: () => import(/* webpackChunkName: "Rewards" */'../components/DashboardBusinessRewards.vue'),
        },
        {
          path: '/affiliate',
          name: 'affiliate',
          component: () => import(/* webpackChunkName: "Affiliate" */'@/views/Affiliate/AffiliatePage.vue'),
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "ProfilePage" */'@/views/profile/ProfilePage.vue'),
          children: [
            {
              path: '/registration-complete/:type',
              name: 'registration-complete',
              component: () => import(/* webpackChunkName: "CompleteProfilePage" */'@/views/profile/CompleteProfilePage.vue'),
            },
          ],
        },
        {
          path: '/profile/preferences',
          name: 'profile-preferences',
          component: () => import(/* webpackChunkName: "ProfilePreferences" */'../components/profile/ProfilePreferences.vue'),
        },
        {
          path: '/history',
          name: 'history',
          component: () => import(/* webpackChunkName: "History" */'../components/DashboardHistory.vue'),
          meta: {
            title: 'Transaction History',
            metaTags: [
              {
                name: 'description',
                content: 'Here you can check all crypto/fiat transactions that have been made',
              },
            ],
            canonicalLink: '/history',
          },
        },
        {
          path: '/transactions/:id',
          name: 'transaction-details',
          component: () => import(/* webpackChunkName: "DashboardTransaction" */'../components/DashboardTransaction.vue'),
        },
        {
          path: '/transactions/:id/edit',
          name: 'editTransaction',
          component: () => import(/* webpackChunkName: "Payments" */'../components/DashboardPayments.vue'),
        },
        {
          path: '/swapinget/:id/view',
          name: 'recurring-transaction-details',
          component: () => import(/* webpackChunkName: "DashboardRecurringTransaction" */'../components/DashboardRecurringTransaction.vue'),
        },
        {
          path: '/verification',
          name: 'verification',
          component: () => import(/* webpackChunkName: "VerificationPage" */'@/views/Verification/VerificationPage.vue'),
          children: [
            {
              path: 'start',
              name: 'verification-start',
              component: () => import(/* webpackChunkName: "VerificationStart" */'@/views/Verification/VerificationStart.vue'),
            },
            {
              path: 'levels',
              name: 'verification-levels',
              component: () => import(/* webpackChunkName: "VerificationLevels" */'@/views/Verification/VerificationLevels.vue'),
            },
            {
              path: 'identity',
              name: 'verification-identity',
              component: () => import(/* webpackChunkName: "VerificationIdentity" */'@/views/Verification/VerificationIdentity.vue'),
            },
            {
              path: 'address',
              name: 'verification-address',
              component: () => import(/* webpackChunkName: "VerificationAddress" */'@/views/Verification/VerificationAddress.vue'),
            },
            {
              path: 'financial',
              name: 'verification-financial',
              component: () => import(/* webpackChunkName: "VerificationFinancial" */'@/views/Verification/VerificationFinancial.vue'),
            },
            {
              path: 'confirmation',
              name: 'verification-confirmation',
              component: () => import(/* webpackChunkName: "VerificationConfirmation" */'@/views/Verification/VerificationConfirmation.vue'),
            },
            {
              path: 'review',
              name: 'verification-review',
              component: () => import(/* webpackChunkName: "VerificationReview" */'@/views/Verification/VerificationReview.vue'),
            },
            {
              path: 'complete',
              name: 'verification-complete',
              component: () => import(/* webpackChunkName: "VerificationComplete" */'@/views/Verification/VerificationComplete.vue'),
            },
            {
              path: 'country-mismatch',
              name: 'verification-country-mismatch',
              component: () => import(/* webpackChunkName: "VerificationCountryMismatch" */'@/views/Verification/VerificationCountryMismatch.vue'),
            },
            {
              path: 'no-service',
              name: 'verification-no-service',
              component: () => import(/* webpackChunkName: "VerificationNoService" */'@/views/Verification/VerificationNoService.vue'),
            },
            {
              path: 'continue',
              name: 'verification-continue',
              component: () => import(/* webpackChunkName: "VerificationContinue" */'@/views/Verification/VerificationContinue.vue'),
            },
          ],
        },
        {
          path: '/swapincollect',
          name: 'swapincollect',
          component: () => import(/* webpackChunkName: "SwapinCollectPage" */'@/views/SwapinCollect/SwapinCollectPage.vue'),
        },
        {
          path: '/swapincollect/overview',
          name: 'swapincollect-overview',
          component: () => import(/* webpackChunkName: "SwapinCollectOverview" */'@/views/SwapinCollect/SwapinCollectOverview.vue'),
        },
      ],
    },
    {
      path: '/',
      component: () => import(/* webpackChunkName: "Public" */'../views/Public.vue'),
      children: [
        {
          path: '/',
          name: 'main',
          component: () => import(/* webpackChunkName: "MainPage" */'@/views/MainPage.vue'),
        },
        {
          path: '/terms',
          name: 'terms',
          component: () => import(/* webpackChunkName: "Terms" */'../views/Terms.vue'),
        },
        {
          path: '/terms/business',
          name: 'terms-business',
          component: () => import(/* webpackChunkName: "TermsBusiness" */'../views/TermsBusiness.vue'),
        },
        {
          path: '/fees',
          name: 'fees',
          component: () => import(/* webpackChunkName: "Fees" */'../views/Fees.vue'),
        },
        {
          path: '/privacy',
          name: 'privacy',
          component: () => import(/* webpackChunkName: "Privacy" */'../views/Privacy.vue'),
        },
        {
          path: '/supported-countries',
          name: 'supported-countries',
          component: () => import(/* webpackChunkName: "SupportedCountries" */'../views/SupportedCountries.vue'),
        },
        {
          path: '/processors',
          name: 'processors',
          component: () => import(/* webpackChunkName: "Processors" */'../views/Processors.vue'),
        },
        {
          path: '/transaction-confirmation/:token',
          name: 'transaction-confirmation',
          component: () => import(/* webpackChunkName: "TransactionConfirmation" */'../views/TransactionConfirmation.vue'),
        },
        {
          path: '/wallet-confirmation/:email/:token',
          name: 'wallet-confirmation',
          component: () => import(/* webpackChunkName: "WalletConfirmation" */'../views/WalletConfirmation.vue'),
        },
        {
          path: '/login/:email/:token',
          name: 'login-by-link',
          component: () => import(/* webpackChunkName: "LoginByLink" */'../views/LoginByLink.vue'),
        },
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "Login" */'../views/Login.vue'),
          meta: {
            title: 'Log In',
            metaTags: [
              {
                name: 'description',
                content: 'Log in to the Swapin dashboard before converting your crypto assets to fiat',
              },
            ],
            canonicalLink: '/login',
          },
        },
        {
          path: '/signup',
          name: 'signup',
          component: () => import(/* webpackChunkName: "Signup" */'../views/Signup.vue'),
          meta: {
            title: 'Sign Up',
            metaTags: [
              {
                name: 'description',
                content: 'Sign Up to get access to the Swapin dashboard and start to convert crypto to euro or British pound',
              },
            ],
            canonicalLink: '/signup',
          },
        },
        {
          path: '/password-reset/:email/:token',
          name: 'password-reset',
          component: () => import(/* webpackChunkName: "PasswordReset" */'../views/PasswordReset.vue'),
          meta: {
            metaTags: [
              {
                name: 'referrer',
                content: 'no-referrer',
              },
            ],
          },
        },
        {
          path: '/unsubscribed',
          name: 'usubscribed',
          component: () => import(/* webpackChunkName: "Unsubscribed" */'../views/Unsubscribed.vue'),
        },
        {
          path: '/merge-accounts',
          name: 'merge-accounts',
          component: () => import(/* webpackChunkName: "MergeAccounts" */'../views/MergeAccounts.vue'),
        },
        {
          path: '/404',
          name: 'not-found',
          component: () => import(/* webpackChunkName: "NotFound" */'../views/NotFound.vue'),
        },
        {
          path: '/join/:referralCode',
          name: 'join',
          component: () => import(/* webpackChunkName: "ReferralCodeSignup" */'../views/ReferralCodeSignup.vue'),
        },
        {
          path: '*',
          component: () => import(/* webpackChunkName: "VoucherCampaign" */'../views/VoucherCampaign.vue'),
        },
      ],
    },
  ],
  scrollBehavior(to) {
    const selector = to.hash || 'body';
    return {
      selector: selector,
      offset: { x: 0, y: 80 },
    };
  },
});

router.afterEach(() => {
  // make sure the sidebar is closed after navigating
  app.$store.commit('sidebar/isSidebarOpen', false);
});

router.beforeEach((to, from, next) => {
  currentRouteMeta = to.matched.slice()
    .reverse()
    .find((r) => r.meta);
  if (currentRouteMeta.meta.metaTags && currentRouteMeta.meta.metaTags.length > 0) {
    manageMetaTags();
  }

  document.title = (to.meta.title || DEFAULT_TITLE) + TITLE_SUFFIX;

  if (to.meta.canonicalLink) {
    const linkTag = document.createElement('link');
    linkTag.setAttribute('rel', 'canonical');
    linkTag.setAttribute('href', `${window.location.protocol}//${window.location.host}${to.meta.canonicalLink}`);
    document.head.appendChild(linkTag);
  }

  if (!to.query.lang) {
    i18n.initialized.then(() => {
      next({
        ...to,
        query: {
          ...to.query,
          lang: i18n.i18next.language,
        },
      });
    });
  } else {
    next();
  }
});

export default router;

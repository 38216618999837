import Vue from 'vue';
import { VueAuthenticate } from 'vue-authenticate';
import { defaultConfig, promptConfig } from '../config/auth.config';
import http from './http.service';
import { getObjectProperty } from '../utils/auth';

// Required for vue-auth to hook into our http service
// To attach token to requests etc
Vue.prototype.$http = http;

// We need to manage two sets of vue-auth with different configurations
// Required to pass different options to social authentication providers.
const instances = {};

const makeInstance = (config) => {
  const instance = new VueAuthenticate(Vue.prototype.$http, config);

  instance.unlink = function unlink(provider) {
    return http.get(`auth/unlink/${provider}`);
  };

  instance.merge = function merge(close_user_token, primary_user_token) {
    return http.post('auth/merge', {
      close_user_token,
      primary_user_token,
    })
      .then((res) => {
        this.setToken(res, 'token');
        return res.data;
      });
  };

  instance.loginByLink = function loginByLink(email, token) {
    return http.post(`auth/login/${email}/${token}`)
      .then((res) => {
        this.setTokenIfLoggedOut(res);
        return res.data;
      });
  };

  instance.resendLoginLink = function resendLoginLink(email) {
    return http.post(`auth/resend_login_link/${email}`)
      .then((res) => res.data);
  };

  instance.checkMerge = function checkMerge() {
    return http.get('auth/merge')
      .then((res) => res.data);
  };

  instance.updateToken = function updateToken(response) {
    if (response && response.headers && response.headers.hasOwnProperty('authorization')) {
      const token = response.headers.authorization.split(' ')[1];
      this.storage.setItem(this.tokenName, token);
    }
  };

  instance.setToken = function setToken(response, tokenPath) {
    if (response[this.options.responseDataKey]) {
      response = response[this.options.responseDataKey];
    }

    const responseTokenPath = tokenPath || this.options.tokenPath;
    const token = getObjectProperty(response, responseTokenPath);

    if (token && this.getToken() !== token) {
      this.storage.setItem(this.tokenName, token);
    }
  };

  instance.sendPIN = function sendPIN() {
    return http.get('auth/pin')
      .then((res) => res.data);
  };

  instance.validatePIN = function validatePIN(PIN, verificationId) {
    return http.post(`auth/pin/${PIN}/${verificationId}`)
      .then((res) => res.data);
  };

  /**
   * Logout from all devices
   */
  instance.logoutAll = function logoutAll() {
    if (instance.isAuthenticated()) {
      instance.logout();
    }

    return http.post('auth/logout');
  };

  instance.setTokenIfLoggedOut = function setTokenIfLoggedOut(response) {
    if (!this.isAuthenticated()) {
      this.setToken(response, 'token');
    }
  };

  return instance;
};

const getInstance = (type) => {
  if (typeof type === 'undefined') {
    type = 'default';
  }

  if (!instances.hasOwnProperty(type)) {
    const config = type === 'prompt' ? promptConfig : defaultConfig;
    instances[type] = makeInstance(config);
  }
  return instances[type];
};

export default getInstance;

import throttle from 'lodash.throttle';

import {
  PROFILE_TYPE,
  PROFILE_STATUS,
  PROFILE_SECTION,
  COMPANY_PROFILE_SECTION,
} from '@/const';

import Profile from '@/services/profile.service';
import Checkups from '@/services/checkups.service';
import Notifications from '@/services/notifications.service';
import Uploads from '@/services/uploads.service';
import Errors from '@/utils/errors';

import {
  isSectionVisible,
  isSectionCompleted,
  isSectionDisabled,
  getSectionErrors,
} from '@/utils/profileSections';

const STATUS_CHECK_INTERVAL = 30000;

let statusCheckTimer = null;

const initialState = () => ({
  profile: null,
  profileErrors: {},
  selectedSectionKey: null,
  submittedSections: [],
  verificationData: null,
  collectLinks: null,
});

export default {
  namespaced: true,

  state: initialState(),

  mutations: {
    setProfile(state, payload) {
      state.profile = payload;
      state.verificationData = null;
    },

    setSelectedSectionKey(state, section) {
      state.selectedSectionKey = section;
    },

    setSectionSubmitted(state, section) {
      if (!state.submittedSections.includes(section)) {
        state.submittedSections.push(section);
      }
    },

    setProfileErrors(state, errors) {
      state.profileErrors = errors;
    },

    setVerificationData(state, data) {
      state.verificationData = data;
    },

    setCollectLinks(state, data) {
      state.collectLinks = data;
    },
  },

  actions: {
    getProfile: throttle(
      ({ commit, dispatch }) => Profile.get()
        .then((profile) => {
          commit('setProfile', profile);
          commit('setProfileErrors', profile.notices);

          if (profile && profile.status === PROFILE_STATUS.PENDING) {
            dispatch('startStatusCheck');
          }

          return profile;
        }),
      500, { trailing: false },
    ),

    updateProfile({ commit, dispatch }, { data, isSilent }) {
      return Profile.save(data)
        .then((res) => {
          commit('setProfile', res.user);
          commit('setProfileErrors', res.user.notices);

          if (res.user && res.user.status === PROFILE_STATUS.PENDING) {
            dispatch('startStatusCheck');
          }

          if (!isSilent) {
            Notifications.notify('success', res.message);
          }

          return res;
        });
    },

    checkAndSaveProfile({
      commit, state, dispatch, getters,
    }, { data, selectedLevel }) {
      const onSuccess = ({ status, level, notices }) => {
        commit('setVerificationData', {
          status,
          level,
          notices,
        });

        return {
          level,
          notices,
        };
      };

      if (getters.isProfileIncomplete) {
        return dispatch('updateProfile', { data, isSilent: true })
          .then(({ user }) => onSuccess(user))
          .catch((error) => {
            const { errors } = error.response.data;

            commit('setProfileErrors', errors);
          });
      }

      const profileData = {
        ...data,
        transaction_volume_id: selectedLevel || state.profile.selected_verification_level,
      };

      return Profile.getFutureLevel(profileData)
        .then(async ({ status, level, invalid_fields }) => {
          if (status === PROFILE_STATUS.PENDING) {
            return dispatch('updateProfile', { data: profileData, isSilent: true })
              .then(({ user }) => onSuccess(user));
          }

          if (selectedLevel) {
            await dispatch('updateProfile', {
              data: {
                ...state.profile,
                selected_verification_level: selectedLevel,
              },
              isSilent: true,
            });
          } else {
            commit('setProfile', profileData);
          }

          const notices = invalid_fields || [];

          commit('setProfileErrors', notices);

          return onSuccess({
            status,
            level,
            notices,
          });
        })
        .catch((error) => {
          const { errors } = error.response.data;

          commit('setProfileErrors', errors);
        });
    },

    async continueVerification({ state, dispatch }) {
      await dispatch('setVerificationData');

      return {
        ...state.verificationData,
        notValidID: state.profile.has_submitted_id && !state.profile.is_id_valid,
      };
    },

    async setVerificationData({ state, getters, commit }) {
      if (state.verificationData) {
        return;
      }

      if (getters.isProfileIncomplete) {
        const { level, notices } = state.profile;

        commit('setVerificationData', {
          level,
          notices,
        });
        return;
      }

      const { level, invalid_fields } = await Profile.getFutureLevel({
        ...state.profile,
        transaction_volume_id: state.profile.selected_verification_level,
      });
      const notices = invalid_fields || [];

      commit('setVerificationData', {
        level,
        notices,
      });
    },

    async cancelVerification({ state, dispatch, commit }) {
      // FIXME: temporarily hotfix. profile has no needed properties, like files
      await dispatch('getProfile');

      const { checked_level, utility_invoice, sof_doc } = state.profile;
      const data = {
        ...state.profile,
        selected_verification_level: checked_level,
        transaction_volume_id: checked_level || 1,
      };

      await Promise.allSettled([
        ...(utility_invoice && (checked_level ? checked_level > 2 : true)
          ? [Uploads.cancelFile(utility_invoice.id)]
          : []
        ),
        ...(sof_doc && !checked_level
          ? [Uploads.cancelFile(sof_doc.id)]
          : []
        ),
        dispatch('updateProfile', { data, isSilent: true }),
      ]);

      commit('setVerificationData', null);
    },

    resubmitID({ commit }) {
      return Profile.resetIdv().then((res) => {
        commit('setProfile', res.user);
        commit('setProfileErrors', res.user.notices);
      });
    },

    startIDVerification() {
      return Checkups.getIdentityCheckSession();
    },

    verificationDone({ dispatch }) {
      return Checkups.identityDocumentsSubmitted()
        .then((res) => {
          dispatch('getProfile');
          return res;
        });
    },

    startStatusCheck({ state, dispatch }) {
      if (!statusCheckTimer) {
        statusCheckTimer = setInterval(() => {
          Profile.getStatus().then((status) => {
            if (state.profile.status !== status) {
              dispatch('stopStatusCheck');
              dispatch('getProfile');
            }
          });
        }, STATUS_CHECK_INTERVAL);
      }
    },

    stopStatusCheck() {
      if (statusCheckTimer) {
        clearInterval(statusCheckTimer);
        statusCheckTimer = null;
      }
    },

    setNextSection({ state, getters, commit }) {
      const { selectedSectionKey } = state;
      const { visibleSections } = getters;

      const currentSectionIndex = visibleSections.findIndex((item) => item.key === selectedSectionKey);
      const sortedSections = [
        ...visibleSections.slice(currentSectionIndex, visibleSections.length),
        ...visibleSections.slice(0, currentSectionIndex),
      ];
      const nextSection = sortedSections.find((item) => !item.isCompleted && !item.isDisabled);

      if (nextSection && nextSection.key !== selectedSectionKey) {
        commit('setSelectedSectionKey', nextSection.key);
      }
    },

    goToIncompleteSectionByNotices({ commit, dispatch }, notices) {
      commit('setProfileErrors', notices);
      dispatch('setNextSection');
    },

    getCollectLinks({ commit }) {
      return Profile.getCollectLinks().then((data) => {
        commit('setCollectLinks', data);
      });
    },

    resetState({ state, dispatch }) {
      Object.assign(state, initialState());
      dispatch('stopStatusCheck');
    },
  },

  getters: {
    isRequiredToVerify(state) {
      return state.profile && state.profile.status === PROFILE_STATUS.COMPLETE && state.profile.is_verify_required;
    },

    isProfileIncomplete(state) {
      return state.profile && state.profile.status === PROFILE_STATUS.INCOMPLETE;
    },

    isProfilePending(state) {
      return state.profile && state.profile.status === PROFILE_STATUS.PENDING;
    },

    isProfileComplete(state) {
      return state.profile && state.profile.status === PROFILE_STATUS.COMPLETE && !state.profile.is_verify_required;
    },

    isProfileNoService(state) {
      return state.profile && state.profile.status === PROFILE_STATUS.NO_SERVICE;
    },

    has3rdPartyPaymentsCapability(state) {
      return state.profile && state.profile.has_3rd_party_payments_capability;
    },

    isPersonalProfile(state) {
      return state.profile && state.profile.type === PROFILE_TYPE.PERSONAL;
    },

    isCompanyProfile(state) {
      return state.profile && state.profile.type === PROFILE_TYPE.COMPANY;
    },

    isVerifiedProfile(state, getters) {
      if (!state.profile || getters.isRequiredToVerify) {
        return false;
      }

      const { status, checked_level } = state.profile;
      const isCheckedLevelVerified = checked_level
        && [PROFILE_STATUS.PENDING, PROFILE_STATUS.INCOMPLETE].includes(status);

      return status === PROFILE_STATUS.COMPLETE || isCheckedLevelVerified;
    },

    profileType(state, getters) {
      const { isPersonalProfile, isCompanyProfile } = getters;

      if (isPersonalProfile) {
        return 'personal';
      }

      if (isCompanyProfile) {
        return 'company';
      }

      return '';
    },

    userName(state) {
      const {
        first_name,
        last_name,
        email,
      } = state.profile;

      return {
        short: first_name || email,
        full: first_name && last_name ? `${first_name} ${last_name}` : email,
      };
    },

    sections(state, getters) {
      const {
        profile,
        profileErrors,
      } = state;

      if (!profile) {
        return [];
      }

      const allSections = getters.isPersonalProfile
        ? PROFILE_SECTION
        : COMPANY_PROFILE_SECTION;

      return Object.values(allSections)
        .map((section) => ({
          key: section,
          isCompleted: isSectionCompleted(section, profile, profileErrors),
          isDisabled: isSectionDisabled(section, profile, profileErrors, allSections),
          errors: getSectionErrors(section, profile, profileErrors),
        }));
    },

    visibleSections(state, getters) {
      const { profile } = state;

      if (!profile) {
        return [];
      }

      return getters.sections.filter(({ key }) => isSectionVisible(key, profile, !profile.reg_submit_at));
    },

    notRequiredNotices(state) {
      const notRequiredNotices = Object.entries(state.profileErrors).reduce((acc, [key, value]) => {
        const notices = value.filter((val) => !val.includes('-required'));

        return {
          ...acc,
          ...(notices.length > 0 ? { [key]: notices } : {}),
        };
      }, {});

      return new Errors({}, notRequiredNotices);
    },

    activeCollectLink(state) {
      return state.collectLinks?.find(({ status }) => status === 'ACCEPTED');
    },
  },
};

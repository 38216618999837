import { mobileAndTabletCheck, isIOS } from '@/utils/helpers';

const IS_MOBILE = mobileAndTabletCheck();

const isTrustWallet = () => typeof window !== 'undefined' && window.ethereum && !window.ethereum.isSafePal && (window.ethereum.isTrust || window.ethereum.isTrustWallet);

export default {
  id: 'trust',
  name: 'Trust Wallet',
  imageSrc: '/img/wallets/wallet-trust.svg',
  isInstalled: () => isTrustWallet(),
  isEnabled: () => !IS_MOBILE || isTrustWallet(),
  getProvider: () => window.ethereum,
};

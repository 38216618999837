import Track from '@/services/track.service';

const onClick = (event, data) => {
  Track.send(event, data);
};

const sendEvent = {
  bind(element, { arg, value }) {
    element.addEventListener('click', () => onClick(arg, value));
  },

  unbind(element, { arg, value }) {
    element.removeEventListener('click', () => onClick(arg, value));
  },
};

export default sendEvent;

import http from './http.service';

class TransactionVolumes {
  getTransactionVolumes(type) {
    return http.get(`transaction_volumes/${type}`)
      .then((res) => res.data);
  }
}

export default new TransactionVolumes();

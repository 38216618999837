export const ERC20_ABI = [
  /**
   * Returns the token decimals.
   */
  'function decimals() view returns (uint256)',
  /**
   * Returns the amount of tokens owned by `account`.
   */
  'function balanceOf(address account) external view returns (uint256)',

  /**
   * Moves `amount` tokens from the caller's account to `recipient`.
   *
   * Returns a boolean value indicating whether the operation succeeded.
   *
   * Emits a {Transfer} event.
   */
  'function transfer(address recipient, uint256 amount) external returns (bool)',

  /**
   * Emitted when `value` tokens are moved from one account (`from`) to
   * another (`to`).
   *
   * Note that `value` may be zero.
   */
  'event Transfer(address indexed from, address indexed to, uint256 value)',
];

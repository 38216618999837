export default {
  namespaced: true,
  state: {
    isInviteFriendModalOpen: false,
    selectedReferralLink: null,
  },
  mutations: {
    isInviteFriendModalOpen(state, payload) {
      state.isInviteFriendModalOpen = payload;
    },
    selectedReferralLink(state, payload) {
      state.selectedReferralLink = payload;
    },
  },
  actions: {
  },
};

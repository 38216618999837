import http from './http.service';
import Auth from './auth.service';
import { uuidv4 } from '../utils/helpers';
import app from '../main';

class Transactions {
  constructor() {
    this.statusPingInterval = null;
  }

  getTransaction(id, hideLoading = false) {
    return http.get(`transactions/${id}`, { hideLoading })
      .then((res) => res.data.invoice);
  }

  getTransactionHistory(id, hideLoading = false) {
    return http.get(`transactions/${id}/history`, { hideLoading })
      .then((res) => res.data.invoice);
  }

  getTransactionStatus(id, hideLoading = false) {
    return http.get(`transactions/${id}/status`, { hideLoading })
      .then((res) => res.data.invoice);
  }

  createTransaction(transaction) {
    return http.post('transactions', transaction)
      .then((res) => {
        Auth().setTokenIfLoggedOut(res);
        return res.data.invoice;
      });
  }

  editTransaction(transaction) {
    return http.post(`transactions/${transaction.transaction_key}`, transaction)
      .then((res) => res.data.invoice);
  }

  getMyTransactions(options) {
    const {
      limit,
      offset,
      startDate,
      endDate,
      type,
      status,
      coin,
      receiver,
    } = options;

    const params = {
      limit,
      offset,
      period_start: startDate,
      period_end: endDate,
      is_recurring_transfer: type,
      status,
      coin,
      receiver,
    };

    return http.get('transactions/me', { params }).then((res) => res.data);
  }

  getCollectorTransactions(options) {
    const {
      limit,
      offset,
      startDate,
      endDate,
    } = options;

    const params = {
      limit,
      offset,
      period_start: startDate,
      period_end: endDate,
    };

    return http.get('transactions/collect', { params }).then((res) => res.data);
  }

  getRecentTransactions() {
    return http.get('transactions/latest')
      .then((res) => res.data.invoices.map((invoice) => {
        invoice.tempId = uuidv4();
        return invoice;
      }));
  }

  confirmTransaction(transaction, params) {
    return http.post(`transactions/${transaction.transaction_key}/confirm`, { params })
      .then((res) => res.data.invoice);
  }

  cancelTransaction(transaction) {
    return http.get(`transactions/${transaction.transaction_key}/cancel`)
      .then((res) => res.data.invoice);
  }

  createTemplate(transactionId) {
    return http.post(`transactions/${transactionId}/template`)
      .then((res) => res.data);
  }

  getTemplate(templateId) {
    return http.get(`transactions/template/${templateId}`)
      .then((res) => res.data.template);
  }

  useVoucher(transaction, voucherCode) {
    const data = {
      ...transaction,
      voucher: voucherCode,
    };
    return this.editTransaction(data);
  }

  removeVoucher(transaction) {
    const data = {
      ...transaction,
      voucher: '',
    };
    return this.editTransaction(data);
  }

  pingStatus(transactionKey) {
    this.stopStatusPing();
    this.statusPingInterval = setInterval(() => {
      this.getTransactionStatus(transactionKey, true)
        .then((res) => {
          app.$store.dispatch('transactions/statusUpdated', res);

          if (this.isFinalStatus(res.status)) {
            this.stopStatusPing();
          }
        });
    }, 7000);
  }

  pingStatus2(transactionKey) {
    this.stopStatusPing();
    this.statusPingInterval = setInterval(() => {
      this.getTransactionHistory(transactionKey, true)
        .then((res) => {
          app.$store.dispatch('transactions/statusUpdated', res);

          if (this.isFinalStatus(res.status)) {
            this.stopStatusPing();
          }
        });
    }, 7000);
  }

  stopStatusPing() {
    clearInterval(this.statusPingInterval);
  }

  isFinalStatus(status) {
    return ['X', 'Z', 'R'].indexOf(status) !== -1;
  }

  hasPaid(status) {
    return status === 'U';
  }

  getUnfinishedTransactions() {
    return http.get('transactions/unfinished')
      .then((res) => res.data.invoices);
  }

  getTicket(code) {
    return http.get(`transactions/ticket/${code}`)
      .then((res) => res.data);
  }

  buyTicket(transaction) {
    return http.post('transactions/ticket', transaction)
      .then((res) => res.data);
  }

  getReceipt(transaction_key, email) {
    return http.post(`transactions/${transaction_key}/ticket_receipt`, {
      email,
    })
      .then((res) => res.data);
  }

  getReceiptBlob(transaction_key, receipt) {
    return http.get(`/transactions/${transaction_key}/receipt/${receipt}`, { responseType: 'blob' })
      .then((res) => res.data);
  }

  getCollectorCsv(params) {
    return http.get('/transactions/collect/csv', { params, responseType: 'blob' })
      .then((res) => res.data);
  }

  getStatusNameKey(coin, status) {
    return `statuses:${coin === 'ESCT' ? 'esct' : 'default'}-${status}-name`;
  }

  getStatusMessageKey(coin, status) {
    return `statuses:${coin === 'ESCT' ? 'esct' : 'default'}-${status}-message`;
  }

  getCountdownMinutes(transaction) {
    return transaction.conversion_service ? 20 : 30;
  }
}

export default new Transactions();

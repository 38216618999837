import throttle from 'lodash.throttle';
import SOFs from '../services/sofs.service';

export default {
  namespaced: true,
  state: {
    all: [],
    company: [],
    pep: [],
  },
  mutations: {
    all(state, payload) {
      state.all = payload;
    },
    company(state, payload) {
      state.company = payload;
    },
    pep(state, payload) {
      state.pep = payload;
    },
  },
  actions: {
    getSOFs: throttle(
      ({ commit, state }) => (
        state.all.length > 0
          ? new Promise((resolve) => resolve(state.all))
          : SOFs.getSOFs('private')
      ).then((volumes) => commit('all', volumes)),
      500, { trailing: false },
    ),

    getCompanySOFs: throttle(
      ({ commit, state }) => (
        state.company.length > 0
          ? new Promise((resolve) => resolve(state.company))
          : SOFs.getSOFs('company')
      ).then((volumes) => commit('company', volumes)),
      500, { trailing: false },
    ),

    getPepSOFs: throttle(
      ({ commit, state }) => (
        state.pep.length > 0
          ? new Promise((resolve) => resolve(state.pep))
          : SOFs.getSOFs('pep')
      ).then((volumes) => commit('pep', volumes)),
      500, { trailing: false },
    ),
  },
};

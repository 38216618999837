import { getFormData } from '@/utils/form';

import http from './http.service';

class Profile {
  get() {
    return http.get('me')
      .then((res) => res.data);
  }

  save(profile) {
    const data = getFormData(profile);

    return http.post('me', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((res) => res.data);
  }

  getVouchers(filter) {
    const params = {};
    if (filter) {
      params.filter = filter;
    }

    return http.get('me/vouchers', {
      params,
    })
      .then((res) => res.data.vouchers);
  }

  addVoucher(voucherCode) {
    return http.post(`me/vouchers/${voucherCode}`)
      .then((res) => res.data);
  }

  getReferralCodes() {
    return http.get('me/referral_codes')
      .then((res) => res.data);
  }

  inviteFriend(params) {
    return http.post('me/referral_codes/invite', { ...params })
      .then((res) => res.data);
  }

  createReferralCode(label) {
    return http.post('me/referral_codes', { label })
      .then((res) => res.data);
  }

  deleteReferralCode(referralCodeId) {
    return http.delete(`me/referral_codes/${referralCodeId}`)
      .then((res) => res.data);
  }

  getTransactionStreak() {
    return http.get('me/streak')
      .then((res) => res.data);
  }

  getReferralStats() {
    return http.get('me/referral_stats')
      .then((res) => res.data);
  }

  getStatus() {
    return http.get('me/status')
      .then((res) => res.data.status);
  }

  getAgentStats(params) {
    return http.get('me/agent_stats', { params })
      .then((res) => res.data);
  }

  getPayoutHistory() {
    return http.get('me/agent_payouts')
      .then((res) => res.data);
  }

  getFutureLevel(profile) {
    const data = getFormData(profile);

    return http.post('me/check', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((res) => res.data);
  }

  getPayoutReceiptBlob(id, type) {
    return http.get(`me/agent_payout/${id}/receipt/${type}`, { responseType: 'blob' })
      .then((res) => res.data);
  }

  resetIdv() {
    return http.get('me/reset-idv')
      .then((res) => res.data);
  }

  getCollectLinks() {
    return http.get('me/collect_links')
      .then((res) => res.data);
  }
}

export default new Profile();

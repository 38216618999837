import Contracts from '../services/contracts.service';

export default {
  namespaced: true,
  state: {
    contract: null,
    isContractModalOpen: false,
  },
  mutations: {
    isContractModalOpen(state, payload) {
      state.isContractModalOpen = payload;
    },
    newContract(state, payload) {
      state.contract = payload;
      state.isContractModalOpen = true;
    },
  },
  actions: {
    checkContract({ commit }) {
      return Contracts.check('terms')
        .then((res) => {
          if (res.contract) {
            commit('newContract', res.contract);
          }
        });
    },
    acceptContract({ commit }, contract) {
      return Contracts.accept(contract)
        .then(() => commit('isContractModalOpen', false));
    },
  },
};

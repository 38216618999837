import http from './http.service';

class Contracts {
  getContracts() {
    return http.get('contracts')
      .then((res) => res.data);
  }

  getContract(id) {
    return http.get(`contracts/${id}`)
      .then((res) => res.data);
  }

  getLatest(type) {
    return http.get(`contracts/latest/${type}`)
      .then((res) => res.data);
  }

  check(type) {
    return http.get(`contracts/check/${type}`)
      .then((res) => res.data);
  }

  accept(contract) {
    return http.post(`contracts/accept/${contract.id}`)
      .then((res) => res.data);
  }

  getTermsBlob(id) {
    return http.get(`contracts/${id}/pdf`, { responseType: 'blob' })
      .then((res) => res.data);
  }
}

export default new Contracts();

<template>
  <Modal
    class="deposit-modal"
    :value="modalOpen"
    size="md"
    :footer="false"
    @input="updateModalOpen"
  >
    <div slot="header">
      <button
        type="button"
        class="close"
        @click="close"
      >
        <i class="material-icons md-18">close</i>
      </button>
      <h4 class="modal-title">
        {{ $t('title') }}
      </h4>
    </div>
    <div
      v-if="depositInfo"
      v-html="$t('deposit-info', {
        coin: depositInfo.coin,
        receiver: depositInfo.coin_buying_receiver,
        iban: depositInfo.coin_buying_iban,
        bic: depositInfo.coin_buying_bic,
        reference: depositInfo.coin_buying_reference,
      })"
    />
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from 'uiv/src/components/modal/Modal';

export default {
  name: 'Deposit',
  i18nOptions: { namespaces: 'deposit' },
  components: {
    Modal,
  },
  computed: {
    ...mapState({
      modalOpen: (state) => state.balances.isDepositModalOpen,
      depositInfo: (state) => state.balances.depositInfo,
    }),
  },

  methods: {
    updateModalOpen(val) {
      this.$store.commit('balances/isDepositModalOpen', val);
    },
    close() {
      this.updateModalOpen(false);
    },
  },
};
</script>

import { AUTH_POPUP_KEY } from '@/const';

const defaultConfig = {
  name: 'default',
  tokenHeader: 'Authorization',
  tokenPath: 'token',

  providers: {
    facebook: {
      name: `${AUTH_POPUP_KEY}-facebook`,
      url: '/auth/facebook',
      clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
      scope: ['email'],
    },
    google: {
      name: `${AUTH_POPUP_KEY}-google`,
      url: '/auth/google',
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      scope: ['email', 'profile'],
    },
    linkedin: {
      name: `${AUTH_POPUP_KEY}-linkedin`,
      url: '/auth/linkedin',
      responseType: 'code',
      clientId: process.env.VUE_APP_LINKEDIN_CLIENT_ID,
      scope: ['r_emailaddress', 'r_liteprofile'],
      requiredUrlParams: ['scope'],
    },
    live: {
      name: `${AUTH_POPUP_KEY}-live`,
      url: '/auth/live',
      clientId: process.env.VUE_APP_LIVE_CLIENT_ID,
      authorizationEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
      scope: ['openid', 'profile', 'email', 'User.Read'],
    },
  },
};

const promptConfig = JSON.parse(JSON.stringify(defaultConfig));
promptConfig.name = 'prompt';

promptConfig.providers.facebook.requiredUrlParams = ['display', 'scope', 'auth_type'];
promptConfig.providers.facebook.authType = 'reauthenticate';

promptConfig.providers.google.requiredUrlParams = ['scope', 'prompt'];
promptConfig.providers.google.prompt = 'select_account';

promptConfig.providers.linkedin.requiredUrlParams = ['scope'];
promptConfig.providers.linkedin.prompt = 'login';

promptConfig.providers.live.requiredUrlParams = ['display', 'scope', 'prompt'];
promptConfig.providers.live.prompt = 'login';

export {
  promptConfig,
  defaultConfig,
};

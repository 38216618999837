<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <Tabs
          pills
          class="vouchers__pills"
          @change="filterChanged"
        >
          <Tab
            v-for="filter in filters"
            :key="filter"
            :title="$t(`filter-${filter}`)"
          />
        </Tabs>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <VouchersList
          :vouchers="vouchers"
          :show-redeem-buttons="showRedeemButtons"
          @use-voucher="useVoucher($event)"
        />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-xs-12">
        <p>
          <small class="text-gray">{{ $t('notice') }}</small>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <p class="light-bold">
          {{ $t('someone-gave-you-voucher') }}
        </p>
        <div class="voucher-form">
          <AppInput
            v-model="voucherCode"
            :label="$t('voucher-code')"
          />
          <button
            class="btn btn-primary"
            type="button"
            @click="addVoucher"
          >
            {{ $t('add-voucher') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Tabs from 'uiv/src/components/tabs/Tabs';
import Tab from 'uiv/src/components/tabs/Tab';
import AppInput from '@/components/forms/AppInput.vue';
import VouchersList from './VouchersList.vue';

export default {
  name: 'MyVouchers',
  i18nOptions: { namespaces: 'my-vouchers' },
  components: {
    Tabs,
    Tab,
    AppInput,
    VouchersList,
  },
  props: {
    showRedeemButtons: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      voucherCode: null,
      filters: ['usable', 'expired'],
      selectedFilter: 'usable',
    };
  },
  computed: {
    ...mapState({
      vouchers: (state) => state.transactions.vouchers,
      loggedIn: (state) => state.auth.isAuthenticated,
      filter: (state) => state.transactions.vouchersFilter,
    }),
  },
  mounted() {
    if (this.loggedIn) {
      this.getVouchers();
    }
  },
  methods: {
    getVouchers() {
      this.$store.dispatch('transactions/getVouchers');
    },
    addVoucher() {
      this.$store.dispatch('transactions/addVoucher', this.voucherCode);
      this.voucherCode = null;
    },
    useVoucher(voucher) {
      this.$store.dispatch('transactions/useVoucher', voucher.code);
    },
    filterChanged(i) {
      this.selectedFilter = this.filters[i];
      this.$store.dispatch('transactions/vouchersfilterChanged', this.selectedFilter);
    },
  },
};
</script>

import { mobileAndTabletCheck } from '@/utils/helpers';

const IS_MOBILE = mobileAndTabletCheck();

export default {
  id: 'tron',
  name: 'Tron',
  isComingSoon: true,
  isInstalled: () => false,
  isEnabled: () => !(IS_MOBILE && typeof window !== 'undefined' && window.ethereum),
  imageSrc: '/img/wallets/wallet-tron.svg',
  getProvider: () => {},
};

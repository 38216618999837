const defaultLocale = 'ru';
const formatOptions = {
  locale: defaultLocale,
  style: 'currency',
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
};

export const formatMoney = (value, currency = 'EUR', fractionDigits = null) => {
  if (value === null || value === undefined) {
    return '';
  }

  if (fractionDigits) {
    value = +value.toFixed(fractionDigits);
  }

  const formattedValue = value.toLocaleString(defaultLocale, { ...formatOptions, currency });
  return formattedValue.replace(',', '.');
};

export const getApproximateAmount = (sum, rate) => {
  if (!sum || !rate) {
    return 0;
  }

  return (Math.ceil((sum / rate) * 10000) / 10000).toFixed(4);
};

import binance from './connectors/binance';
import coinbase from './connectors/coinbase';
import metaMask from './connectors/metaMask';
import tron from './connectors/tron';
import trust from './connectors/trust';
import walletConnect from './connectors/walletConnect';

export const WALLETS = [
  metaMask,
  trust,
  coinbase,
  binance,
  tron,
  walletConnect,
].filter((wallet) => !!wallet.isEnabled()).sort((a, b) => Number(!!a.isComingSoon) - Number(!!b.isComingSoon));

export const getWalletById = (id) => WALLETS.find((wallet) => wallet.id === id);

import http from './http.service';

class Countries {
  getCountries() {
    return http.get('countries')
      .then((res) => res.data);
  }
}

export default new Countries();

<template>
  <Modal
    class="vouchers-modal"
    :value="modalOpen"
    :footer="false"
    @input="updateModalOpen"
  >
    <div slot="header">
      <button
        type="button"
        class="close"
        @click="close"
      >
        <i class="material-icons md-18">close</i>
      </button>
      <h4 class="modal-title">
        {{ $t('apply-voucher') }}
      </h4>
    </div>
    <MyVouchers />
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from 'uiv/src/components/modal/Modal';
import MyVouchers from './MyVouchers';

export default {
  name: 'VouchersModal',
  i18nOptions: { namespaces: 'vouchers-modal' },
  components: {
    Modal,
    MyVouchers,
  },
  computed: {
    ...mapState({
      modalOpen: (state) => state.transactions.isVoucherModalOpen,
    }),
  },
  methods: {
    updateModalOpen(val) {
      this.$store.commit('transactions/isVoucherModalOpen', val);
    },
    close() {
      this.updateModalOpen(false);
    },
  },
};
</script>

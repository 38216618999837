import http from './http.service';

class Balances {
  getBalances() {
    return http.get('balances')
      .then((res) => res.data);
  }

  getBalance(balanceId) {
    return http.get(`balances/${balanceId}`)
      .then((res) => res.data);
  }

  getBalanceTransactions(balanceId, params) {
    return http.get(`balances/${balanceId}/transactions`, {
      params,
    })
      .then((res) => res.data);
  }

  openBalance(coin) {
    return http.post('balances', {
      coin,
    })
      .then((res) => res.data);
  }

  activateBalance(balance) {
    return http.post(`balances/${balance.account_id}/activate`)
      .then((res) => res.data);
  }

  getBalanceDepositAddress(balanceId) {
    return http.get(`balances/${balanceId}/deposit`)
      .then((res) => res.data);
  }

  getDefaultBalance() {
    return http.get('balances/default')
      .then((res) => res.data);
  }

  requestConfirmation(invoiceTransactionKey) {
    return http.post(`transactions/${invoiceTransactionKey}/request_confirmation`)
      .then((res) => res.data);
  }

  confirmTransaction(code, transaction_key = null, account_id = null) {
    return http.post(`balances/confirm_transaction/${code}`, {
      invoice_transaction_key: transaction_key,
      account_id,
    })
      .then((res) => res.data);
  }

  getCoins() {
    return http.get('balances/coins')
      .then((res) => res.data);
  }

  getBalanceDepositOrders(balanceId, params) {
    return http.get(`balances/${balanceId}/deposit_orders`, {
      params,
    })
      .then((res) => res.data);
  }

  createDepositOrder(balanceId, payload) {
    return http.post(`balances/${balanceId}/deposit_orders`, payload)
      .then((res) => res.data);
  }

  getBalanceExchangeOrders(balanceId, params) {
    return http.get(`balances/${balanceId}/exchange_orders`, {
      params,
    })
      .then((res) => res.data);
  }

  createExchangeOrder(order) {
    const params = JSON.parse(JSON.stringify(order));

    if (params.order.hasOwnProperty('to_coin') && params.order.to_coin) {
      params.order.to_coin = params.order.to_coin.coin;
    }
    if (params.order.hasOwnProperty('to_account') && params.order.to_account) {
      delete params.order.to_account;
    }
    if (params.order.hasOwnProperty('from_account') && params.order.from_account) {
      params.order.from_account_id = params.order.from_account.account_id;
      delete params.order.from_account;
    }
    if (params.order.hasOwnProperty('from_coin') && params.order.from_coin) {
      delete params.order.from_coin;
    }
    if (params.order.hasOwnProperty('from_amount') && params.order.hasOwnProperty('to_amount')) {
      if (params.order.from_amount === null) {
        delete params.order.from_amount;
      } else if (params.order.to_amount === null) {
        delete params.order.to_amount;
      }
    }

    return http.post('balances/exchange_orders', params)
      .then((res) => res.data);
  }

  getUserExchangeRates() {
    return http.get('balances/user/exchange_rates')
      .then((res) => res.data);
  }
}

export default new Balances();

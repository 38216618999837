export default {
  namespaced: true,
  state: {
    isSidebarOpen: false,
  },
  mutations: {
    isSidebarOpen(state, payload) {
      state.isSidebarOpen = payload;
    },
  },
};

<template>
  <Modal
    v-model="isExplainerModalOpen"
    size="lg"
    :header="false"
    :footer="false"
    class="explainer-modal"
  >
    <button
      type="button"
      class="close"
      @click="isExplainerModalOpen = false"
    >
      <i class="material-icons">close</i>
    </button>
    <div
      v-if="isExplainerModalOpen"
      class="embed-responsive embed-responsive-16by9"
    >
      <iframe
        src="https://www.youtube.com/embed/nhMw4STD59Q?rel=0&autoplay=1"
        class="embed-responsive-item"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  </Modal>
</template>

<script>
import Modal from 'uiv/src/components/modal/Modal';

export default {
  components: {
    Modal,
  },
  computed: {
    isExplainerModalOpen: {
      get() {
        return this.$store.state.isExplainerModalOpen;
      },
      set(value) {
        this.$store.commit('updateIsExplainerModalOpen', value);
      },
    },
  },
};
</script>

export default {
  namespaced: true,

  state: {
    isWalletModalOpen: false,
    openedFrom: '',
  },

  mutations: {
    setIsWalletModalOpen(state, isOpen) {
      state.isWalletModalOpen = isOpen;
    },

    setOpenedFrom(state, from) {
      state.openedFrom = from;
    },
  },

  actions: {
    showWalletModal({ commit }, { from }) {
      commit('setIsWalletModalOpen', true);
      commit('setOpenedFrom', from);
    },

    hideWalletModal({ commit }) {
      commit('setIsWalletModalOpen', false);
      commit('setOpenedFrom', '');
    },
  },
};

<template>
  <Modal
    class="auth-modal"
    :value="isOpen"
    title="Reset password"
    :footer="false"
    @input="change"
  >
    <form
      novalidate
      @submit.prevent="sendResetLink"
    >
      <p class="fs-lg text-gray mb-3">
        {{ $t('Please enter your Swapin user account email. We\'ll send you the password reset link to set a new password for your account.') }}
      </p>
      <AppInput
        v-model="email"
        :label="$t('email')"
        :errors="errors"
        field="email"
        type="email"
        inputnode="email"
      />
      <p class="mt-3">
        <button
          type="submit"
          class="btn btn-primary btn-lg"
        >
          {{ $t('send') }}
        </button>
      </p>
    </form>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from 'uiv/src/components/modal/Modal';
import AppInput from '@/components/forms/AppInput';
import Errors from '../utils/errors';
import Password from '../services/password.service';
import Notifications from '../services/notifications.service';

export default {
  name: 'PasswordResetRequestModal',
  i18nOptions: { namespaces: 'password-reset-request-modal' },
  components: {
    Modal,
    AppInput,
  },
  data() {
    return {
      email: '',
      errors: new Errors(),
    };
  },
  computed: {
    ...mapState({
      isOpen: (state) => state.auth.isPasswordResetRequestModalOpen,
    }),
  },
  methods: {
    change(event) {
      this.$store.commit('auth/isPasswordResetRequestModalOpen', event);
    },
    sendResetLink() {
      Password.sendResetLink(this.email)
        .then((res) => {
          Notifications.notify('success', res.message);

          this.$store.commit('auth/isPasswordResetRequestModalOpen', false);
          this.email = null;
          return res;
        })
        .catch((err) => this.errors.set(err.response.data.errors));
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group",class:{
    'has-error': _vm.isFieldsHasError,
    'has-action': _vm.actionIcon || _vm.addonIcon,
    'form-group--loading': _vm.isLoading,
  }},[_c('div',{staticClass:"relative a-form-group",class:{'input-group': _vm.actionIcon || _vm.inputGroupSuffix || _vm.$slots.suffix || _vm.inputGroupPrefix}},[(_vm.inputGroupPrefix)?_c('div',{staticClass:"input-group-addon"},[_vm._v(" "+_vm._s(_vm.inputGroupPrefix)+" ")]):_vm._e(),_c('input',{ref:"input",staticClass:"form-control",class:_vm.inputClass,attrs:{"id":_vm.inputId,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":_vm.type,"autocomplete":_vm.autocomplete,"maxlength":_vm.maxlength,"inputmode":_vm.inputmode,"required":_vm.required,"pattern":_vm.pattern,"min":_vm.min,"max":_vm.max,"step":_vm.step},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":function($event){return _vm.$emit('change', $event.target.value)},"focus":_vm.onFocus,"blur":_vm.onBlur}}),_c('div',{staticClass:"form-group__loading-spinner"}),_c('label',{class:{
        'active-label' : _vm.isFocused || !!_vm.value || _vm.forceActiveLabel,
        '-disabled': _vm.disabled
      },attrs:{"for":_vm.inputId}},[_vm._v(_vm._s(_vm.label))]),(_vm.inputGroupSuffix)?_c('div',{staticClass:"input-group-addon"},[_vm._v(" "+_vm._s(_vm.inputGroupSuffix)+" ")]):_vm._e(),(_vm.$slots.suffix)?_c('div',{staticClass:"input-group-addon"},[_vm._t("suffix")],2):_vm._e(),(_vm.addonIcon)?_c('button',{staticClass:"btn btn-link btn-input-addon",attrs:{"type":"button"},on:{"click":_vm.onAddonClick}},[_c('i',{staticClass:"material-icons md-16"},[_vm._v(_vm._s(_vm.addonIcon))])]):_vm._e(),(_vm.actionIcon)?_c('span',{staticClass:"input-group-btn"},[_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":_vm.onActionClick}},[_c('i',{staticClass:"material-icons md-16"},[_vm._v(_vm._s(_vm.actionIcon))])])]):_vm._e()]),_vm._t("default"),_c('AppHint',{attrs:{"error":_vm.error,"error-right":_vm.errorRight,"hint":_vm.hint,"hint-right":_vm.hintRight || _vm.hintLength}}),(_vm.errors)?_c('AppError',{attrs:{"errors":_vm.errors,"field":_vm.field,"exclude-required-errors":_vm.excludeRequiredErrors}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <span
    v-if="hasError"
    class="help-block"
  >
    <p
      v-for="(error, index) in errorsList"
      :key="index"
      class="help-block__error text-danger"
    >
      {{ $t(error) }}
    </p>
    <p
      v-for="(notice, index) in noticesList"
      :key="index"
      class="help-block__notice text-danger"
    >
      <AppNotice :value="notice" />
    </p>
  </span>
</template>

<script>
import AppNotice from './AppNotice';

export default {
  components: {
    AppNotice,
  },
  props: {
    errors: {
      type: Object,
      default: null,
    },
    field: {
      type: String,
      default: '',
    },
    excludeRequiredErrors: Boolean,
  },

  computed: {
    errorsList() {
      const errors = this.errors?.get(this.field) || [];

      return this.getErrors(errors);
    },

    noticesList() {
      const notices = this.errors?.getNotice(this.field) || [];

      return this.getErrors(notices);
    },

    hasError() {
      return this.errorsList.length > 0 || this.noticesList.length > 0;
    },
  },

  methods: {
    getErrors(list) {
      const filtered = this.excludeRequiredErrors
        ? list.filter((item) => !item.includes('-required'))
        : list;

      return filtered;
    },
  },
};
</script>

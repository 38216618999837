import { PROFILE_SECTION } from '@/const';

const SECTION_FIELDS = {
  [PROFILE_SECTION.PERSONAL]: [
    'first_name',
    'last_name',
    'country',
    'phone_country',
    'phone',
    'birth_country',
    'citizenship',
    'is_pep_related',
    'is_beneficiary',
    'country_code',
    'birth_country_code',
    'citizenship_code',
    'phone_dial_code',
  ],
  [PROFILE_SECTION.IDENTITY]: [
    'identity_document',
    'has_submitted_id',
  ],
  [PROFILE_SECTION.ADDITIONAL]: [
    'address_country',
    'county',
    'city',
    'address',
    'postalcode',
    'utility_invoice',
    'transaction_purpose',
    'employer_name',
    'net_income',
    'sof',
    'sof_doc',
    'address_country_code',
    'sof_id',
  ],
  [PROFILE_SECTION.CONFIRM]: [
    'terms',
    'consent',
  ],
};

export default SECTION_FIELDS;

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export function formatDate(val, toFormat = 'DD.MM.YYYY HH:mm', fromFormat = 'YYYY-MM-DD HH:mm:ss ZZ') {
  if (val) {
    return dayjs(String(val), fromFormat)
      .format(toFormat);
  }
}

<template>
  <span v-html="nl2Br" />
</template>

<script>
export default {
  i18nOptions: { namespaces: 'validation' },
  props: ['value'],
  computed: {
    nl2Br() {
      return this.$t(this.value).split('\n')
        .join('<br>');
    },
  },
};
</script>

import dayjs from 'dayjs';
import Templates from '@/services/templates.service';
import Notifications from '@/services/notifications.service';

export const DEFAULT_TEMPLATE = {
  name: null,
  receiver_name: null,
  receiver_iban: null,
  receiver_account: '',
  receiver_sort_code: '',
  receiver_address: '',
  receiver_street: '',
  receiver_city: '',
  receiver_zip_code: '',
  receiver_country_code: '',
  receiver_is_self: true,
  receiver_is_company: false,
  sum_currency: null,
  currency: 'EUR',
  description: null,
  reference: null,
  coin: null,
  refund_address: null,
  remainder: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
};

export default {
  namespaced: true,

  state: {
    template: null,
    templates: null,
    isModalOpen: false,
    isRemainderOpen: false,
    remainder: null,
  },

  mutations: {
    template(state, template) {
      state.template = template;
    },

    templates(state, templates) {
      state.templates = templates;
    },

    isModalOpen(state, isOpen) {
      state.isModalOpen = isOpen;
    },

    isRemainderOpen(state, isOpen) {
      state.isRemainderOpen = isOpen;
    },

    resetTemplate(state) {
      state.template = { ...DEFAULT_TEMPLATE };
    },

    remainder(state, payload) {
      state.remainder = payload;
    },
  },

  actions: {
    getTemplates({ commit }) {
      return Templates
        .getTemplates()
        .then((res) => {
          commit('templates', res);
        });
    },

    getTemplate({ commit }, templateId) {
      return Templates.getTemplate(templateId)
        .then((template) => commit('template', template));
    },

    saveTemplate({ dispatch }, data) {
      return Templates[data.id
        ? 'updateTemplate'
        : 'createTemplate'](data)
        .then((res) => {
          Notifications.notify('success', res.message);
          if (res.hasOwnProperty('id')) {
            dispatch('getTemplate', res.id);
          }
        })
        .then(() => dispatch('getTemplates'));
    },

    resetState({ commit }) {
      commit('templates', null);
      commit('template', null);
    },

    remainderChanged({ commit }, payload) {
      commit('remainder', payload);
    },
  },
};

import WalletConnectProvider, {
  OPTIONAL_EVENTS,
  OPTIONAL_METHODS,
} from '@walletconnect/ethereum-provider';

import { mobileAndTabletCheck } from '@/utils/helpers';

import { CHAINS_RPC_URLS } from '../../chains';

const IS_MOBILE = mobileAndTabletCheck();
const [defaultChain, ...optionalChains] = Object.keys(CHAINS_RPC_URLS);

export default {
  id: 'walletconnect',
  name: 'WalletConnect',
  imageSrc: '/img/wallets/wallet-walletconnect.svg',
  isInstalled: () => true,
  isEnabled: () => !(IS_MOBILE && typeof window !== 'undefined' && window.ethereum),
  getProvider: () => WalletConnectProvider.init({
    projectId: process.env.VUE_APP_WALLETCONNECT_PROJECT_ID,
    isNewChainsStale: true,
    chains: [defaultChain],
    optionalChains,
    rpcMap: CHAINS_RPC_URLS,
    showQrModal: true,
    optionalMethods: OPTIONAL_METHODS,
    optionalEvents: OPTIONAL_EVENTS,
    qrModalOptions: {
      themeVariables: {
        '--wcm-z-index': '1100',
      },
    },
  }),
};

import http from './http.service';

class SOFs {
  getSOFs(type) {
    return http.get(`sofs/${type}`)
      .then((res) => res.data);
  }
}

export default new SOFs();

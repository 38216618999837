import http from './http.service';

class Coins {
  getCoins() {
    return http.get('coins')
      .then((res) => res.data.coins);
  }

  coinImg(coin) {
    return `https://cdn.swapin.com/coins/png/${coin}.png`;
  }
}

export default new Coins();

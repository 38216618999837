var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{staticClass:"deposit-modal",attrs:{"value":_vm.modalOpen,"size":"md","footer":false},on:{"input":_vm.updateModalOpen}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":_vm.close}},[_c('i',{staticClass:"material-icons md-18"},[_vm._v("close")])]),_c('h4',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.$t('title'))+" ")])]),(_vm.depositInfo)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('deposit-info', {
      coin: _vm.depositInfo.coin,
      receiver: _vm.depositInfo.coin_buying_receiver,
      iban: _vm.depositInfo.coin_buying_iban,
      bic: _vm.depositInfo.coin_buying_bic,
      reference: _vm.depositInfo.coin_buying_reference,
    }))}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
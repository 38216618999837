import throttle from 'lodash.throttle';
import TransactionVolumes from '../services/transaction-volumes.service';

export default {
  namespaced: true,
  state: {
    all: [],
    company: [],
  },
  mutations: {
    all(state, payload) {
      state.all = payload;
    },
    company(state, payload) {
      state.company = payload;
    },
  },
  actions: {
    getTransactionVolumes: throttle(
      ({ commit, state }) => (
        state.all.length > 0
          ? new Promise((resolve) => resolve(state.all))
          : TransactionVolumes.getTransactionVolumes('private')
      ).then((volumes) => commit('all', volumes)),
      500, { trailing: false },
    ),
    getCompanyTransactionVolumes: throttle(
      ({ commit, state }) => (
        state.company.length > 0
          ? new Promise((resolve) => resolve(state.company))
          : TransactionVolumes.getTransactionVolumes('company')
      ).then((volumes) => commit('company', volumes)),
      500, { trailing: false },
    ),
  },
};

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);
dayjs.extend(utc);

export const FORMAT = {
  SERVER: 'YYYY-MM-DD HH:mm:ss',
  SERVER_DATE: 'YYYY-MM-DD',
  LOCAL: 'DD.MM.YYYY HH:mm',
  LOCAL_DATE: 'DD.MM.YYYY',
};

export const getUtcNow = (format = FORMAT.SERVER) => {
  const date = dayjs.utc();

  return format ? date.format(format) : date;
};

export const getLocalDatetime = (value, format = FORMAT.LOCAL, fromFormat = FORMAT.SERVER) => {
  const date = dayjs(value, fromFormat);

  return format ? date.format(format) : date;
};

export const getLocalDate = (value) => getLocalDatetime(value, FORMAT.LOCAL_DATE, FORMAT.SERVER_DATE);

export const getServerDate = (value) => getLocalDatetime(value, FORMAT.SERVER_DATE, FORMAT.LOCAL_DATE);

export const isValidServerDate = (value) => dayjs(value, FORMAT.SERVER_DATE).isValid();

import { COMPANY_PROFILE_SECTION } from '@/const';

const SECTION_FIELDS = {
  [COMPANY_PROFILE_SECTION.COMPANY]: [
    'company_code',
    'company_email',
    'phone_country',
    'phone',
    'company_website',
    'company_date_of_incorpotation',
    'company_vat_no_liable',
    'company_vat_no',
    'phone_dial_code',
    'company_address_country',
    'company_address_county',
    'company_address_city',
    'company_address_street',
    'company_address_postalcode',
    'company_business_address_different',
    'company_business_address_country',
    'company_business_address_county',
    'company_business_address_city',
    'company_business_address_street',
    'company_business_address_postalcode',
    'company_proof_of_incorporation',
    'company_address_country_code',
    'company_business_address_country_code',
    'company_legal_form',
  ],
  [COMPANY_PROFILE_SECTION.COMPANY_ACTIVITY]: [
    'company_main_activity',
    'company_license_needed',
    'company_license_url',
    'company_clients_country_code',
    'company_activity_gambling',
    'company_activity_art_dealer',
    'company_activity_private_banking',
    'company_activity_weapons_and_ammo',
    'company_activity_cash_transport',
    'company_activity_currency_exchange',
    'company_activity_crypto',
    'company_activity_precious_metals',
  ],
  [COMPANY_PROFILE_SECTION.COMPANY_IDENTITY]: [
    'first_name',
    'last_name',
    'birth_country',
    'citizenship',
    'country',
    'address',
    'basis_of_rep_type',
    'basis_of_rep_file',
    'country_code',
    'birth_country_code',
    'citizenship_code',
    'basis_of_rep_type',
    'identity_document',
    'has_submitted_id',
  ],
  [COMPANY_PROFILE_SECTION.COMPANY_OWNERSHIP]: [
    'company_entities',
    'company_entities_pep',
    'company_entities_ubos_id_docs',
  ],
  [COMPANY_PROFILE_SECTION.COMPANY_ADDITIONAL]: [
    'sof_id',
    'sof_doc',
    'company_entities_peps_sofs',
  ],
  [COMPANY_PROFILE_SECTION.COMPANY_CONFIRM]: [],
};

export default SECTION_FIELDS;

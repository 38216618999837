import dayjs from 'dayjs';
import Balances from '../services/balances.service';
import app from '../main';

function setDefaultParams(state) {
  const params = {
    sort_order: 'DESC',
  };
  if (state.periodFrom) {
    params.period_start = dayjs(state.periodFrom, 'YYYY-MM-DD')
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .unix();
  }
  if (state.periodTo) {
    params.period_end = dayjs(state.periodTo, 'YYYY-MM-DD')
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .unix();
  }
  return params;
}

const emptyExchangeOrder = {
  to_amount: null,
  to_coin: null,
  to_coin_address: '',
  from_account: null,
  from_coin: null,
  from_amount: null,
};

export default {
  namespaced: true,
  state: {
    balances: null,
    selectedBalance: null,
    depositInfo: null,
    transactions: null,
    depositOrders: null,
    exchangeOrders: null,
    userExchangeRates: null,
    periodFrom: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    periodTo: dayjs().format('YYYY-MM-DD'),
    isDepositModalOpen: false,
    isTransactionModalOpen: false,
    isAddNewBalanceModalOpen: false,
    isDepositOrderModalOpen: false,
    isNewDepositOrderModalOpen: false,
    isExchangeOrderModalOpen: false,
    isNewExchangeOrderModalOpen: false,
    selectedTransaction: null,
    selectedDepositOrder: null,
    selectedExchangeOrder: null,
    coins: [],
    exchangeOrder: { ...emptyExchangeOrder },
  },
  mutations: {
    isTransactionModalOpen(state, payload) {
      state.isTransactionModalOpen = payload;
    },
    isDepositOrderModalOpen(state, payload) {
      state.isDepositOrderModalOpen = payload;
    },
    isNewDepositOrderModalOpen(state, payload) {
      state.isNewDepositOrderModalOpen = payload;
    },
    isExchangeOrderModalOpen(state, payload) {
      state.isExchangeOrderModalOpen = payload;
    },
    isNewExchangeOrderModalOpen(state, payload) {
      state.isNewExchangeOrderModalOpen = payload;
    },
    isAddNewBalanceModalOpen(state, payload) {
      state.isAddNewBalanceModalOpen = payload;
    },
    isDepositModalOpen(state, isDepositModalOpen) {
      state.isDepositModalOpen = isDepositModalOpen;
    },
    selectedTransaction(state, payload) {
      state.selectedTransaction = payload;
    },
    selectedDepositOrder(state, payload) {
      state.selectedDepositOrder = payload;
    },
    selectedExchangeOrder(state, payload) {
      state.selectedExchangeOrder = payload;
    },
    transactions(state, payload) {
      state.transactions = payload;
    },
    periodFrom(state, payload) {
      state.periodFrom = payload;
    },
    periodTo(state, payload) {
      state.periodTo = payload;
    },
    selectedBalance(state, payload) {
      state.selectedBalance = payload;
    },
    depositInfo(state, payload) {
      state.depositInfo = payload;
    },
    balances(state, balances) {
      state.balances = balances;
    },
    coins(state, coins) {
      state.coins = coins;
    },
    depositOrders(state, depositOrders) {
      state.depositOrders = depositOrders;
    },
    exchangeOrders(state, exchangeOrders) {
      state.exchangeOrders = exchangeOrders;
    },
    userExchangeRates(state, userExchangeRates) {
      state.userExchangeRates = userExchangeRates;
    },
    resetExchangeOrder(state) {
      state.exchangeOrder = { ...emptyExchangeOrder };
    },
    exchangeOrderProps(state, props) {
      const order = { ...state.exchangeOrder };

      Object.keys(props)
        .forEach((field) => {
          order[field] = props[field];
        });

      state.exchangeOrder = order;
    },
  },
  actions: {
    addAccountDataToExchangeOrder({ commit, state }) {
      commit('exchangeOrderProps', {
        from_account: state.selectedBalance,
      });
    },
    getEnabledCoins({ commit }) {
      Balances.getCoins()
        .then((res) => {
          commit('coins', res.coins);
        });
    },
    getBalances({ state, commit, dispatch }) {
      Balances.getBalances()
        .then((res) => {
          commit('balances', res.accounts);
          if (state.balances.length > 0 && !state.selectedBalance) {
            dispatch('balanceChanged', state.balances[0]);
          }
        });
    },
    openNewBalance({ dispatch }, payload) {
      return Balances.openBalance(payload.coin ? payload.coin : null)
        .then((res) => {
          dispatch('getBalances');
          if (res.hasOwnProperty('account')) {
            dispatch('balanceChanged', res.account);
          }

          return res;
        });
    },
    activateBalance(store, balance) {
      return Balances.activateBalance(balance);
    },
    openDepositModal({ commit }, payload) {
      Balances.getBalanceDepositAddress(payload.account_id)
        .then((res) => {
          commit('depositInfo', res.account);
          commit('isDepositModalOpen', true);
        });
    },
    periodFromChanged({ commit, dispatch }, payload) {
      commit('periodFrom', payload);
      dispatch('getBalanceTransactions');
      dispatch('getBalanceDepositOrders');
      dispatch('getBalanceExchangeOrders');
    },
    periodToChanged({ commit, dispatch }, payload) {
      commit('periodTo', payload);
      dispatch('getBalanceTransactions');
      dispatch('getBalanceDepositOrders');
      dispatch('getBalanceExchangeOrders');
    },
    balanceChanged({ commit, dispatch }, balance) {
      commit('selectedBalance', balance);
      if (balance) {
        dispatch('getBalanceTransactions');
        dispatch('getBalanceDepositOrders');
        dispatch('getBalanceExchangeOrders');
      }
    },
    createDepositOrder({ state }, payload) {
      return Balances.createDepositOrder(state.selectedBalance.account_id, payload);
    },
    createExchangeOrder({ state }, payload) {
      return Balances.createExchangeOrder(payload);
    },
    getUserExchangeRates({ commit }) {
      Balances.getUserExchangeRates()
        .then((res) => commit('userExchangeRates', res.rates));
    },
    getBalanceTransactions({ state, commit }) {
      const params = setDefaultParams(state);

      Balances.getBalanceTransactions(state.selectedBalance.account_id, params)
        .then((res) => commit('transactions', res.transactions));
    },
    getBalanceDepositOrders({ state, commit }) {
      const params = setDefaultParams(state);

      Balances.getBalanceDepositOrders(state.selectedBalance.account_id, params)
        .then((res) => commit('depositOrders', res.deposit_orders));
    },
    getBalanceExchangeOrders({ state, commit }) {
      const params = setDefaultParams(state);

      Balances.getBalanceExchangeOrders(state.selectedBalance.account_id, params)
        .then((res) => commit('exchangeOrders', res.exchange_orders));
    },
    newTransaction({ rootState, commit }, payload) {
      commit('transactions/clearTransactionForm', null, { root: true });
      const template = {
        coin: payload.account.coin,
        account: payload.account,
        account_id: payload.account.account_id,
      };

      if (payload.hasOwnProperty('withdraw')) {
        template.description = 'Withdrawal';
        template.reference = null;
        template.receiver_name = `${rootState.profile.profile.first_name} ${rootState.profile.profile.last_name}`;
      }

      commit('transactions/transactionFromTemplate', template, { root: true });
      app.$router.push({ name: 'payments' });
    },
    resetState({ commit }) {
      commit('balances', null);
      commit('selectedBalance', null);
      commit('depositInfo', null);
      commit('transactions', null);
      commit('selectedTransaction', null);
      commit('resetExchangeOrder');
    },
  },
  getters: {
    depositLimits(state) {
      return state.coins && state.coins[0] ? state.coins[0].limits : {};
    },
    transactionCoinBalances(state, getters, rootState) {
      return (state.balances || []).filter((balance) => balance.coin === rootState.transactions.formTransaction.coin);
    },
  },
};

<template>
  <div
    v-if="hintErrorText || hintErrorTextRight"
    class="form-group__hint"
  >
    <div
      class="form-group__hint-text"
      :class="{
        'text-danger': error
      }"
    >
      {{ hintErrorText }}
    </div>
    <div
      class="form-group__hint-text form-group__hint-text--right"
      :class="{
        'text-danger': errorRight
      }"
    >
      {{ hintErrorTextRight }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      default: '',
    },
    errorRight: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    hintRight: {
      type: String,
      default: '',
    },
  },

  computed: {
    hintErrorText() {
      return this.error || this.hint;
    },

    hintErrorTextRight() {
      return this.errorRight || this.hintRight;
    },
  },
};
</script>

import throttle from 'lodash.throttle';

import Coins from '@/services/coins.service';

export default {
  namespaced: true,

  state: {
    all: {},
  },

  mutations: {
    setAllCoins(state, payload) {
      state.all = payload;
    },
  },

  actions: {
    getCoins: throttle(
      ({ commit }) => {
        Coins.getCoins().then((coins) => {
          commit('setAllCoins', coins);
        });
      },
      500, { trailing: false },
    ),
  },

  getters: {
    all(state) {
      return Object.keys(state.all).map((key) => state.all[key]);
    },

    noServiceCoin(state, getters) {
      return getters.all.filter((coin) => coin.coin !== 'ESCT');
    },
  },
};

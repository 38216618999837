const CHAIN_ID = {
  ETH_MAINNET: '0x1', // 1
  ETH_TESTNET_SEPOLIA: '0xaa36a7', // 11155111
  BNB_MAINNET: '0x38', // 56
  BNB_TESTNET: '0x61', // 97
};

const ETH_MAINNET = {
  id: CHAIN_ID.ETH_MAINNET,
  name: 'Ethereum Mainnet',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrl: 'https://cloudflare-eth.com',
  explorerUrl: 'https://etherscan.io',
};

const ETH_TESTNET_SEPOLIA = {
  id: CHAIN_ID.ETH_TESTNET_SEPOLIA,
  name: 'Sepolia test network',
  nativeCurrency: {
    name: 'Sepolia Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrl: 'https://rpc.sepolia.org',
  explorerUrl: 'https://sepolia.etherscan.io',
};

const BNB_MAINNET = {
  id: CHAIN_ID.BNB_MAINNET,
  name: 'Binance Smart Chain',
  nativeCurrency: {
    name: 'Binance Coin',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrl: 'https://bsc-dataseed.binance.org',
  explorerUrl: 'https://bscscan.com',
};

const BNB_TESTNET = {
  id: CHAIN_ID.BNB_TESTNET,
  name: 'Binance Smart Chain - Testnet',
  nativeCurrency: {
    name: 'Binance Coin',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  explorerUrl: 'https://testnet.bscscan.com',
};

export const CHAINS = [
  ...(process.env.VUE_APP_ENV_PROD ? [
    ETH_MAINNET,
    BNB_MAINNET,
  ] : [
    ETH_MAINNET,
    BNB_MAINNET,
    ETH_TESTNET_SEPOLIA,
    BNB_TESTNET,
  ]),
];

export const CHAINS_RPC_URLS = CHAINS.reduce((acc, chain) => ({
  ...acc,
  [parseInt(chain.id)]: chain.rpcUrl,
}), {});

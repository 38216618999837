import {
  PROFILE_STATUS,
  PROFILE_TYPE,
  PROFILE_SECTION,
  COMPANY_PROFILE_SECTION,
} from '@/const';

import personalSectionFields from '@/views/profile/utils/personalSectionFields';
import companySectionFields from '@/views/profile/utils/companySectionFields';

const getFields = type => (type === PROFILE_TYPE.PERSONAL ? personalSectionFields : companySectionFields);

const isCompleted = (sectionFields, section, errors) => !sectionFields[section].some((key) => key in errors);

export const getSectionErrors = (section, data, errors) => {
  const fields = getFields(data.type);

  return Object.entries(errors)
    .filter(([key]) => fields[section].includes(key))
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }), {});
};

export const isConfirmSection = (section) => section === PROFILE_SECTION.CONFIRM || section === COMPANY_PROFILE_SECTION.COMPANY_CONFIRM;

export const isSectionCompleted = (section, data, errors) => {
  switch (section) {
    case PROFILE_SECTION.CONFIRM:
    case COMPANY_PROFILE_SECTION.COMPANY_CONFIRM:
      return !!data.reg_submit_at;
    default:
      return isCompleted(getFields(data.type), section, errors);
  }
};

export const isSectionDisabled = (section, data, errors, allSections) => {
  switch (section) {
    case PROFILE_SECTION.IDENTITY:
    case COMPANY_PROFILE_SECTION.IDENTITY:
      return data.status === PROFILE_STATUS.NO_SERVICE;
    case PROFILE_SECTION.CONFIRM:
    case COMPANY_PROFILE_SECTION.COMPANY_CONFIRM:
      return !data.reg_submit_at && Object.values(allSections)
        .filter((item) => !isConfirmSection(item))
        .some((item) => !isSectionCompleted(item, data, errors));
    default:
      return false;
  }
};

export const isSectionVisible = (section, data, isInitialView) => {
  const hasSubmitted = data.reg_submit_at;

  switch (section) {
    case PROFILE_SECTION.ADDITIONAL:
      return isInitialView ? data.level > 1 : hasSubmitted;
    case COMPANY_PROFILE_SECTION.COMPANY_ADDITIONAL:
      return isInitialView ? data.level > 2 : hasSubmitted;
    case PROFILE_SECTION.CONFIRM:
    case COMPANY_PROFILE_SECTION.COMPANY_CONFIRM:
      return isInitialView;
    default:
      return true;
  }
};

<template>
  <div
    class="form-group"
    :class="{
      'has-error': isFieldsHasError,
      'has-action': actionIcon || addonIcon,
      'form-group--loading': isLoading,
    }"
  >
    <div
      class="relative a-form-group"
      :class="{'input-group': actionIcon || inputGroupSuffix || $slots.suffix || inputGroupPrefix}"
    >
      <div
        v-if="inputGroupPrefix"
        class="input-group-addon"
      >
        {{ inputGroupPrefix }}
      </div>
      <input
        :id="inputId"
        ref="input"
        :disabled="disabled"
        :readonly="readonly"
        :type="type"
        :autocomplete="autocomplete"
        :maxlength="maxlength"
        :value="value"
        :inputmode="inputmode"
        :required="required"
        :pattern="pattern"
        :min="min"
        :max="max"
        :step="step"
        class="form-control"
        :class="inputClass"
        @input="$emit('input', $event.target.value)"
        @change="$emit('change', $event.target.value)"
        @focus="onFocus"
        @blur="onBlur"
      >
      <div class="form-group__loading-spinner" />
      <label
        :for="inputId"
        :class="{
          'active-label' : isFocused || !!value || forceActiveLabel,
          '-disabled': disabled
        }"
      >{{ label }}</label>
      <div
        v-if="inputGroupSuffix"
        class="input-group-addon"
      >
        {{ inputGroupSuffix }}
      </div>
      <div
        v-if="$slots.suffix"
        class="input-group-addon"
      >
        <slot name="suffix" />
      </div>
      <button
        v-if="addonIcon"
        class="btn btn-link btn-input-addon"
        type="button"
        @click="onAddonClick"
      >
        <i class="material-icons md-16">{{ addonIcon }}</i>
      </button>
      <span
        v-if="actionIcon"
        class="input-group-btn"
      >
        <button
          class="btn"
          type="button"
          @click="onActionClick"
        >
          <i class="material-icons md-16">{{ actionIcon }}</i>
        </button>
      </span>
    </div>

    <slot />

    <AppHint
      :error="error"
      :error-right="errorRight"
      :hint="hint"
      :hint-right="hintRight || hintLength"
    />

    <AppError
      v-if="errors"
      :errors="errors"
      :field="field"
      :exclude-required-errors="excludeRequiredErrors"
    />
  </div>
</template>

<script>
import { uuidv4 } from '@/utils/helpers';
import AppError from '@/components/AppError.vue';
import AppHint from '@/components/AppHint.vue';

export default {
  name: 'AppInput',
  components: {
    AppError,
    AppHint,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    /** @deprecated */
    errors: {
      type: Object,
      default: null,
    },
    /** @deprecated */
    field: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    inputmode: {
      type: String,
      default: 'text',
    },
    maxlength: {
      type: [String, Number],
      default: '255',
    },
    addonIcon: {
      type: String,
      default: '',
    },
    actionIcon: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: undefined,
    },
    pattern: {
      type: String,
      default: undefined,
    },
    min: {
      type: String,
      default: undefined,
    },
    max: {
      type: String,
      default: undefined,
    },
    step: {
      type: String,
      default: undefined,
    },
    error: {
      type: String,
      default: '',
    },
    errorRight: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    hintRight: {
      type: String,
      default: '',
    },
    required: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    hasError: Boolean,
    forceActiveLabel: Boolean,
    showLengthHint: Boolean,
    inputGroupSuffix: {
      type: String,
      default: '',
    },
    inputGroupPrefix: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    excludeRequiredErrors: Boolean,
    isLoading: Boolean,
  },

  data() {
    return {
      inputId: uuidv4(),
      isFocused: false,
    };
  },

  computed: {
    hintLength() {
      return this.showLengthHint ? `${this.value.length}/${this.maxlength}` : '';
    },

    isFieldsHasError() {
      return this.errors?.has(this.field)
        || this.errors?.hasNotice(this.field)
        || this.hasError
        || this.error
        || this.errorRight;
    },
  },
  methods: {
    onAddonClick() {
      this.$emit('onAddonClick');
    },

    onActionClick() {
      this.$emit('onActionClick');
    },

    onFocus(event) {
      this.isFocused = true;

      this.$emit('focus', event);
    },

    onBlur(event) {
      this.isFocused = false;

      this.$emit('blur', event);
    },

    setInputValue(value) {
      this.$refs.input.value = value;
    },
  },
};
</script>

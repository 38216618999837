import { isValidServerDate } from '@/utils/datetime';

export const isFieldHasError = (field, errors) => {
  if (!errors) {
    return false;
  }

  const fields = Array.isArray(field) ? field : [field];

  return Object.keys(errors).some((key) => fields.includes(key));
};

export const isFormFieldsNotEmpty = (form, fields) => {
  const valuesToCheck = Array.isArray(fields)
    ? Object.entries(form).reduce((acc, [key, value]) => [
      ...acc,
      ...(fields.includes(key) ? [value] : []),
    ], [])
    : Object.values(form);

  return !valuesToCheck.some((field) => !field);
};

export const excludeFieldsFromData = (fields, data) => {
  const form = { ...data };

  for (const field of fields) {
    delete form[field];
  }

  return form;
};

export const isFormDatesValidServerDates = (form, fields) => {
  for (const field of fields) {
    if (!isValidServerDate(form[field])) return false;
  }

  return true;
};

const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data === null || data === undefined ? '' : data;

    formData.append(parentKey, value);
  }
};

export const getFormData = (data) => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};

export default class Errors {
  constructor(errors, notices) {
    this.errors = errors;
    this.notices = notices;
  }

  setNotices(notices) {
    this.notices = notices;
  }

  getNotice(field) {
    if (this.hasNotice(field)) {
      return this.notices[field];
    }
  }

  hasNotice(field) {
    return this.notices && this.notices.hasOwnProperty(field);
  }

  clearNotices() {
    this.notices = {};
  }

  set(errors) {
    this.errors = errors;
  }

  get(field) {
    if (this.has(field)) {
      return this.errors[field];
    }
    return null;
  }

  has(field) {
    return this.errors && this.errors.hasOwnProperty(field);
  }

  clear() {
    this.errors = {};
  }
}

<template>
  <Modal
    :value="isOpen"
    size="lg"
    :backdrop="false"
    :dismiss-btn="false"
    :keyboard="false"
  >
    <div class="alerts alerts--info p-2 mb-5">
      We have updated our Terms of Service. Please review and accept them to continue using our services.
    </div>
    <div
      v-if="contract"
      v-html="contract.content"
    />
    <div slot="footer">
      <button
        class="btn btn-primary"
        :disabled="submitted"
        @click="accept"
      >
        {{ $t('common:accept') }}
      </button>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from 'uiv/src/components/modal/Modal';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      submitted: false,
    };
  },
  computed: {
    ...mapState({
      isOpen: (state) => state.contracts.isContractModalOpen,
      contract: (state) => state.contracts.contract,
    }),
  },
  methods: {
    accept() {
      this.submitted = true;
      this.$store.dispatch('contracts/acceptContract', this.contract)
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

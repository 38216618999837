<template>
  <div
    class="voucher"
    :class="{'voucher--inactive': !canBeUsed}"
  >
    <span
      v-if="hasNewBadge"
      class="voucher__label"
    >New</span>
    <div class="voucher__top">
      <div class="voucher__discount">
        {{ discountText }}
      </div>
      <div class="voucher__description">
        <button
          v-if="showRedeemButton && canBeUsed"
          class="btn btn-block btn-primary"
          @click="useVoucher"
        >
          {{ $t('redeem') }}
        </button>
        <button
          v-if="voucher.expired || voucher.used"
          :disabled="true"
          class="btn btn-block btn-disabled"
        >
          <span v-if="voucher.expired">{{ $t('expired') }}</span>
          <span v-else-if="voucher.used">{{ $t('redeemed') }}</span>
          <span v-else>{{ $t('unusable') }}</span>
        </button>
      </div>
    </div>
    <div class="voucher__rip">
      <div class="voucher__notch" />
      <div class="voucher__notch voucher__notch--right" />
    </div>
    <div class="voucher__bottom">
      <p class="voucher__code">
        <small>{{ voucher.code }}</small>
      </p>
      <p class="voucher__expiration">
        <small>{{ $t('valid-until') }} - {{ formatDate(voucher.valid_until, 'DD MMM YYYY', 'YYYY-MM-DD') }}</small>
      </p>
    </div>
  </div>
</template>
<script>
import { formatMoney } from '@/utils/currency';
import { formatDate } from '@/utils/date.filter';

export default {
  name: 'Voucher',
  i18nOptions: { namespaces: 'voucher' },

  props: {
    voucher: {
      type: Object,
      default: () => ({}),
    },
    showRedeemButton: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    canBeUsed() {
      return this.voucher.usable;
    },

    hasNewBadge() {
      return this.voucher.is_new && this.canBeUsed;
    },

    discountText() {
      if (this.voucher.discount_eur) {
        return formatMoney(this.voucher.discount_eur);
      }

      if (this.voucher.discount_percent) {
        return `-${this.voucher.discount_percent}%`;
      }

      return '';
    },
  },

  methods: {
    formatDate,

    useVoucher() {
      this.$emit('use-voucher', this.voucher);
    },
  },
};
</script>

export default new class {
  openChat() {
    const { Trengo } = window;

    if (Trengo) {
      Trengo.Api.Widget.open('chat');
    }
  }

  setContactData(data) {
    const { Trengo } = window;

    if (Trengo) {
      Trengo.contact_data = {
        ...Trengo.contact_data,
        custom_fields: [
          ...Trengo.contact_data?.custom_fields || [],
          ...data,
        ],
      };
    }
  }
}();

import { mobileAndTabletCheck } from '@/utils/helpers';

const IS_MOBILE = mobileAndTabletCheck();

export default {
  id: 'coinbase',
  name: 'Coinbase Wallet',
  isComingSoon: true,
  isInstalled: () => false,
  isEnabled: () => !(IS_MOBILE && typeof window !== 'undefined' && window.ethereum),
  imageSrc: '/img/wallets/wallet-coinbase.svg',
  getProvider: () => {},
};

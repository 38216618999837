<template>
  <div
    id="app"
    :class="appClass"
  >
    <vue-progress-bar />
    <router-view />
    <ServerUnavailable />
    <PasswordResetRequestModal />
    <ContractModal />
    <ExplainerModal />
    <VouchersModal />
    <DepositModal />
    <WalletModal />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Gtm from '@/services/gtm.service';
import Trengo from '@/services/trengo.service';

import ServerUnavailable from './components/ServerUnavailable';
import PasswordResetRequestModal from './components/PasswordResetRequestModal';
import ContractModal from './components/ContractModal';
import ExplainerModal from './components/ExplainerModal';
import VouchersModal from './components/vouchers/VouchersModal';
import DepositModal from './components/DepositModal';
import WalletModal from './components/WalletModal';

export default {
  components: {
    ServerUnavailable, // () => import(/* webpackChunkName: "ServerUnavailable" */'./components/ServerUnavailable'),
    PasswordResetRequestModal, //  () => import(/* webpackChunkName: "PasswordResetRequestModal" */'./components/PasswordResetRequestModal'),
    ContractModal, // () => import(/* webpackChunkName: "ContractModal" */'./components/ContractModal'),
    ExplainerModal, // () => import(/* webpackChunkName: "ExplainerModal" */'./components/ExplainerModal'),
    VouchersModal, // () => import(/* webpackChunkName: "VouchersModal" */'./components/vouchers/VouchersModal'),
    DepositModal, // () => import(/* webpackChunkName: "DepositModal" */'./components/DepositModal'),
    WalletModal,
  },

  computed: {
    ...mapState({
      profile: (state) => state.profile.profile,
    }),

    ...mapGetters({
      campaignCode: 'campaigns/campaignCode',
    }),

    appClass() {
      const appClass = [];
      if (this.campaignCode) {
        appClass.push(this.campaignCode);
      }
      if (this.$route.name) {
        appClass.push(`route-${this.$route.name}`);
      }
      return appClass.join(' ');
    },
  },

  watch: {
    'profile.id'(newId) {
      if (newId) {
        Gtm.sendEvent('set_user_id', {
          user_id: newId,
        });

        Trengo.setContactData([{
          field_id: 592719,
          value: newId,
        }]);
      }
    },
  },

  created() {
    this.$store.dispatch('init');
  },
};
</script>

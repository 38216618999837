import { mobileAndTabletCheck, isIOS } from '@/utils/helpers';

const IS_MOBILE = mobileAndTabletCheck();

const isMetaMask = () => typeof window !== 'undefined' && window.ethereum && window.ethereum.isMetaMask;

export default {
  id: 'metamask',
  name: 'MetaMask',
  imageSrc: '/img/wallets/wallet-metamask.svg',
  isInstalled: () => isMetaMask(),
  isEnabled: () => !IS_MOBILE || isMetaMask(),
  getProvider: () => window.ethereum,
};

import Notification from 'uiv/src/services/notification/notification';
import { memoizeDebounce } from '../utils/helpers';

class Notifications {
  notify = memoizeDebounce(
    (type, content, title = null, duration = null) => {
      const params = {
        icon: '',
        type: type,
        content,
        title,
      };

      if (duration) {
        params.duration = duration;
      }

      return Notification.notify(params);
    },
    60,
  );

  success(content, title) {
    return this.notify('success', content, title);
  }

  error(content, title) {
    return this.notify('warning', content, title);
  }
}

export default new Notifications();

import http from './http.service';

class Templates {
  getTemplates() {
    return http.get('templates')
      .then((res) => res.data);
  }

  getTemplate(templateId) {
    return http.get(`templates/${templateId}`)
      .then((res) => res.data);
  }

  createTemplate(template) {
    return http.post('templates', template)
      .then((res) => res.data);
  }

  updateTemplate(template) {
    return http.patch(`templates/${template.id}`, template)
      .then((res) => res.data);
  }

  deleteTemplate(template) {
    return http.delete(`templates/${template.id}`)
      .then((res) => res.data);
  }
}

export default new Templates();

import axios from 'axios';
import axiosRetry from 'axios-retry';
import Events from './events.service';
import Notifications from './notifications.service';
import app from '../main';
import { t } from '../utils/i18n.filter';
import Auth from './auth.service';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

let isUnauthorizedStatusHandled = false;

axiosRetry(instance, {
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    if (error.response && [409, 401, 403, 404, 422, 429].includes(error.response.status)) {
      return false;
    }
    return true;
  },
});

instance.interceptors.request.use((request) => {
  if (typeof request.hideLoading === 'undefined' || !request.hideLoading) {
    app.$Progress.start();
  }

  // Set locale
  request.headers.Language = app.$i18n.i18next.language;

  return request;
}, (error) => {
  app.$Progress.finish();

  Notifications.notify(
    'warning',
    t('common:check-connection'),
    t('common:network-error'),
  );
  return Promise.reject(error);
});

instance.interceptors.response.use((response) => {
  isUnauthorizedStatusHandled = false;

  app.$Progress.finish();

  Auth().updateToken(response);

  const { config, data } = response;

  // Show Api errors
  if (!config.isSilentOk && data?.ok === false) {
    Notifications.notify(
      'warning',
      response.data.desc,
      t('common:error'),
      0,
    );

    return Promise.reject(response.data.desc);
  }

  return response;
}, (error) => {
  app.$Progress.finish();

  Auth().updateToken(error.response);

  if (typeof error.response === 'undefined'
    || typeof error.response.status === 'undefined') {
    Notifications.notify(
      'warning',
      t('common:check-connection'),
      t('common:network-error'),
    );
    return Promise.reject(error);
  }

  // Server down
  if (error.response.status >= 500) {
    Events.emit('ServerUnavailable');
    return Promise.reject(error);
  }

  // Form validation errors
  if (error.response.status === 422) {
    Notifications.notify(
      'warning',
      t(error.response.data.message),
      t('messages:form-validation-failed'),
    );
    return Promise.reject(error);
  }

  // Authentication issues
  if (error.response.status === 401) {
    if (!isUnauthorizedStatusHandled) {
      isUnauthorizedStatusHandled = true;
      app.$store.dispatch('auth/logout', app.$route.fullPath);
      Notifications.notify(
        'info',
        t(error.response.data.message),
        t('common:unauthorized'),
      );
    }

    return Promise.reject(error);
  }

  // Api errors that are returned with error codes
  Notifications.notify(
    'warning',
    t(error.response.data[error.response.data.hasOwnProperty('message') ? 'message' : 'error']),
    t('common:error'),
  );
  return Promise.reject(error);
});

export default instance;

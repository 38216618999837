import http from './http.service';
import Auth from './auth.service';

class Password {
  reset(params) {
    return http.post('password/reset', params)
      .then((res) => {
        Auth().setTokenIfLoggedOut(res);
        return res.data;
      });
  }

  sendResetLink(email) {
    return http.post('password/email', { email })
      .then((res) => res.data);
  }
}

export default new Password();

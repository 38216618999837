import http from './http.service';

class Checkups {
  getIdentityCheckSession() {
    return http.get('checkups/identity/check')
      .then((res) => res.data);
  }

  identityDocumentsSubmitted() {
    return http.post('checkups/identity/check')
      .then((res) => res.data);
  }
}

export default new Checkups();

<template>
  <div
    v-if="show"
    class="server-unavailable"
  >
    <div class="container">
      <div class="col-sm-offset-3 col-sm-6">
        <div class="col-sm-8">
          <TheLogo class="logo-light" />
        </div>
        <div class="col-sm-12">
          <h2>{{ $t('title') }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Events from '../services/events.service';
import TheLogo from './TheLogo';

export default {
  name: 'ServerUnavailable',
  i18nOptions: { namespaces: 'server-unavailable' },
  components: {
    TheLogo,
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    Events.on('ServerUnavailable', () => {
      this.show = true;
    });
  },
};
</script>

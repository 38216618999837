import http from './http.service';

class Campaigns {
  getCampaigns() {
    return http.get('campaigns/active')
      .then((res) => res.data);
  }
}

export default new Campaigns();

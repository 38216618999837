import app from '../main';

const codeCookieKey = 'PIIX_CC';
const urlCookieKey = 'PIIX_REFERRAL_URL';

export default {
  namespaced: true,

  state: {
    code: null,
    url: null,
  },

  mutations: {
    code(state, payload) {
      state.code = payload;
    },

    url(state, payload) {
      state.url = payload;
    },
  },

  actions: {
    init({ dispatch }, { code, url }) {
      if (code) {
        dispatch('setCode', code);
      }

      if (url) {
        dispatch('setUrl', url);
      }
    },

    setUrl({ commit }, url) {
      app.$cookies.set(urlCookieKey, url, '24h');
      commit('url', url);
    },

    setCode({ commit }, code) {
      app.$cookies.set(codeCookieKey, code, '24h');
      commit('code', code);
    },
  },

  getters: {
    referralCode: (state) => {
      const { code } = state;

      if (!code && app.$cookies.isKey(codeCookieKey)) {
        return app.$cookies.get(codeCookieKey);
      }

      return code;
    },

    referralUrl: (state) => {
      const { url } = state;

      if (!url && app.$cookies.isKey(urlCookieKey)) {
        return app.$cookies.get(urlCookieKey);
      }

      return url || window.document.location.href;
    },
  },
};

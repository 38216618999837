import './assets/sass/app.scss';

import VueProgressBar from 'vue-progressbar';
import VueCookies from 'vue-cookies';
import Hotjar from 'vue-hotjar';
import VueCalendly from 'vue-calendly';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import Vue from 'vue';
import progressbar from './config/progressbar.config';
import router from './config/router.config';
import i18n from './config/i18n.config';
import store from './store/store';

import App from './App.vue';

Vue.config.productionTip = false;
Vue.use(VueProgressBar, progressbar);
Vue.use(VueCookies);
Vue.use(VueCalendly);

if (process.env.VUE_APP_HOTJAR_ID) {
  Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID,
  });
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://b545b27382904510a9f93dea58467f49@o1262827.ingest.sentry.io/6442006',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['stage.app.piix.eu', 'pre-app.swapin.com', 'app.swapin.com', 'stan.piix.eu'],
      }),
    ],
    tracesSampleRate: 1.0,
    logErrors: true,
  });
}

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
});
app.$mount('#app');

export default app;
